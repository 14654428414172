import * as yup from "yup";

const ReportOrdersReceivableSchema = yup.object().shape({
  start: yup
    .date()
    .required("La fecha de inicio es requerida")
    .typeError("La fecha de inicio debe ser una fecha válida")
    .test(
      "is-before-end",
      "La fecha de inicio no puede ser mayor que la fecha de fin",
      function (value) {
        const { end } = this.parent;
        return !end || value <= end;
      }
    ),
  end: yup
    .date()
    .required("La fecha de fin es requerida")
    .typeError("La fecha de fin debe ser una fecha válida")
    .test(
      "is-after-start",
      "La fecha de fin no puede ser menor que la fecha de inicio",
      function (value) {
        const { start } = this.parent;
        return !start || value >= start;
      }
    ),
  status: yup
    .string()
    .required("El estado es requerido")
    .typeError("El estado debe ser una cadena de texto"),
});

export default ReportOrdersReceivableSchema;
