import React, { useEffect } from 'react';


import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FieldArray, useFormikContext } from 'formik';

import Button from 'react-bootstrap/Button';
import TablePayments from '../orderPayments/tablePayments.js';
import ResumeStatusPayment from '../orderPayments/resumeStatusPayment.js';
import { useCommon } from '../../../../context/CommonContext.js';


function OrderStatusPagadoTable({ tablePaymentsRef, paymentTypeCode, order_status_code, orderValues, handleSubmit }) {
    const { values, setFieldValue, errors, setFieldTouched, submitForm } = useFormikContext();
    const { fetchOrder, setFetchOrder } = useCommon();
    useEffect(() => {
       // console.log("paymentTypeCode", paymentTypeCode);

        setFieldValue('excedente_asegurado', orderValues?.excedente_asegurado);
        setFieldValue('monto_aseguradora_paga', orderValues?.monto_aseguradora_paga);



        let totalPayments = 0;
        values.payments.forEach((payment) => {
            if (payment.payment_amount) {
                totalPayments += Number(payment.payment_amount);
            }
        });
       // console.log("totalPayments", totalPayments);
        if (paymentTypeCode === 'CONTADO') {
            setFieldValue('contadoTotalPayed', totalPayments);
            setFieldValue('totalPendingToPay', orderValues?.total - orderValues?.creditoTotalPayed - totalPayments);
            setFieldValue('totalPendingExcedente', orderValues?.excedente_asegurado - totalPayments);
        } else if (paymentTypeCode === 'CREDITO') {
            setFieldValue('creditoTotalPayed', totalPayments);
            setFieldValue('totalPendingToPay', orderValues?.total - orderValues?.contadoTotalPayed - totalPayments);
        }
       // console.log("totalPayments", totalPayments)

        switch (orderValues?.tipo_pago_id?.code) {
            case 'CONTADO':
                //setFieldValue('totalPendingToPay', orderValues?.totalPendingToPay - totalPayments);
                //setFieldValue('contadoTotalPayed', orderValues?.contadoTotalPayed + totalPayments);
                break;
            case 'CREDITO':
                //setFieldValue('totalPendingToPay', orderValues?.totalPendingToPay - totalPayments);
                //setFieldValue('creditoTotalPayed', orderValues?.creditoTotalPayed + totalPayments);
                break;
            case 'CONTADO/CREDITO':
                if (order_status_code === 'PAGADO_CONTADO') {

                    //setFieldValue('contadoTotalPayed', orderValues?.contadoTotalPayed + totalPayments);
                } else if (order_status_code === 'PAGADO_CREDITO') {
                    //setFieldValue('totalPendingToPay', orderValues?.totalPendingToPay - totalPayments);
                    //setFieldValue('creditoTotalPayed', orderValues?.creditoTotalPayed + totalPayments);
                }
                break;
            default:
                break;
        }

        //setFieldValue('totalPendingExcedente', orderValues?.totalPendingExcedente);


    }, [
        orderValues?.excedente_asegurado,
        orderValues?.monto_aseguradora_paga,
        orderValues?.totalPendingExcedente,
        orderValues?.totalPendingToPay,
        orderValues?.contadoTotalPayed,
        orderValues?.creditoTotalPayed,
        values.payments
    ]);

    return (
        <Form onSubmit={() => {handleSubmit(); setFetchOrder(!fetchOrder);}}>
            <>

                <div className="modal-header">
                    <h4 className="modal-title">Registrar pago recibido {paymentTypeCode === 'CONTADO' ? 'de CONTADO' : 'a CRÉDITO'}</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                </div>
                <div className="modal-body">
                    <ResumeStatusPayment />

                    <TablePayments
                        ref={tablePaymentsRef}
                        paymentTypeCode={paymentTypeCode}
                        valuesKey='payments'
                        disabled={false}
                    />

                    <Row className="mb-1">
                        <Form.Group as={Col} md="12" >
                            <Form.Label>Observaciones {values?.totalPendingExcedente > 0 ? 'del motivo por el que no se cubre el monto Excedente Asegurado' : ''}</Form.Label>
                            <textarea
                                name="observaciones"
                                className={`form-control ${errors.observaciones ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                    setFieldTouched('observaciones', true);
                                    setFieldValue('observaciones', e.target.value);
                                    setFieldValue('clickedButton', 'savePayments');
                                }}
                                value={values.observaciones}
                            ></textarea>
                            <Form.Control.Feedback type="invalid">
                                {errors.observaciones}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {/* <Form.Group as={Col} className="mt-3 ms-3"  >
                        <Form.Check
                            name="need_invoice"
                            type="switch"
                            label="Requiere Factura"
                            checked={values.need_invoice}
                            onChange={(e) => {
                                //setFieldTouched("contado", true);
                                setFieldValue("need_invoice", e.target.checked);
                            }}
                            //onBlur={handleBlur}
                        />
                    </Form.Group> */}

                    <div className="row modal-footer" style={{width:'100%'}}>
                        <Button type="button" data-bs-dismiss="modal" className='btn-white col-5'>Cerrar sin guardar cambios</Button>
                        <Button type="button"
                            onClick={async () => {
                                await setFieldValue('clickedButton', 'savePayments');
                                submitForm();
                            }}
                            disabled={Object.keys(errors).length > 0}
                            className='btn-white col-3'>Guardar pagos
                        </Button>
                        <Button type="button"
                            onClick={async () => {
                                await setFieldValue('clickedButton', 'updateStatus');
                                submitForm();
                            }}
                            className='col-3'
                            disabled={Object.keys(errors).length > 0}>Actualizar estatus
                        </Button>
                    </div>



                </div>

            </>

        </Form>
    );
}

export default OrderStatusPagadoTable;