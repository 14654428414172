import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { format } from "date-fns";

import { es } from "date-fns/locale";

import ServiceColumn from "./ServiceColumn";
import { formatName, formatNumberWithCommas } from "../../utils";
import { getComissionPerCrane, getComissionPerKM, getCurrentFortnight, getOrderType, getReason } from "../../models/ReportOperatorSalary";

const TableOperatorSalary = ({ orders, start, end }) => {
  return (
    <div className="table-responsive mb-3">
    <table className="table table-hover table-panel text-nowrap align-middle mb-0">
      <thead className="sticky-thead table-light text-center">
        <tr>
          <th className="align-middle">Orden</th>
          <th className="align-middle">Tipo de Servicio</th>
          <th className="align-middle">Descripción del servicio</th>
          <th className="align-middle">Fecha</th>
          <th className="align-middle">Total (Sin IVA)</th>
          <th className="align-middle">KM aproximados</th>
          <th className="align-middle">Operador</th>
          <th className="align-middle">Grúa</th>
          <th className="align-middle">Tipo de grúa</th>
          <th className="align-middle">Comisión x Grúa</th>
          <th className="align-middle">Comisión x KM</th>
          <th className="align-middle">Razón</th>
        </tr>
      </thead>

      <tbody>
        {orders
          // .sort(
          //   (a, b) =>
          //     new Date(a.fecha_hora_reporte) - new Date(b.fecha_hora_reporte)
          // )
          // .filter(
          //   (order) =>
          //     new Date(order.fecha_hora_reporte) >= getCurrentFortnight().start &&
          //     new Date(order.fecha_hora_reporte) <= getCurrentFortnight().end
          // )
          .map((order) => (
            <tr className="text-center">
              <td>
                <Row className="mb-2">
                  <Col md="12">
                    <Link to={`/orders/edit/${order.id}`}>
                      {order.folio.toString().padStart(5, "0")}
                    </Link>
                  </Col>
                </Row>
              </td>
              <td>{getOrderType(order)}</td>
              <td>
                <ServiceColumn row={order} />
              </td>
              <td>
                {format(new Date(order.fecha_hora_reporte), "dd/MMM/yyyy", {
                  locale: es,
                })}
              </td>
              <td>${formatNumberWithCommas(order.subtotal) || Number(order.quick_order_price) || '0.00'}</td>
              <td>{!isNaN(Number(order?.totalDistance)) 
                    ? Number(order?.totalDistance).toFixed(2) 
                    : "0.00"} km</td>
              <td>{formatName(order.operador)}</td>
              <td>{order?.Crane?.description}</td>
              <td>{order?.Crane?.CraneTypes?.at(0)?.crane_type_name}</td>
              <td>${formatNumberWithCommas(getComissionPerCrane(order))}</td>
              <td>${formatNumberWithCommas(getComissionPerKM(order))}</td>
              <td>{getReason(order)}</td>
            </tr>
          ))}
      </tbody>
    </table>
    </div>
  );
};

export default TableOperatorSalary;
