import React from "react";
import Loader from "../../../components/loader";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import OrderReceivableForm from "../../../forms/OrderReceivableForm";
import { ReportOrderReceivable } from "../../../models/ReportOrderReceivable";
import ReportOrdersReceivableSchema from "../../../validations/ReportOrdersReceivableSchema";
import axiosClient from "../../../config/axios";
import TableOrdersReceivable from "../../../components/reports/TableOrdersReceivable";

const OrdersReceivable = () => {
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = React.useState([]);

  const formik = useFormik({
    initialValues: ReportOrderReceivable(),
    validationSchema: ReportOrdersReceivableSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const endpoint = `/reports/order-receivable?start=${values.start}&end=${values.end}&status=${values.status}`;

        const { data } = await axiosClient.get(endpoint);
        setOrders(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  React.useEffect(() => {
    formik.handleSubmit();
  }, []);

  return (
    <React.Fragment>
      <Loader isLoading={loading} />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Inicio</Link>
            </li>
            <li className="breadcrumb-item active">Ordenes por cobrar</li>
          </ol>
          <h1 className="page-header mb-0">Reporte de ordenes por cobrar</h1>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-xl-12 card border-0 p-3">
          <OrderReceivableForm formik={formik} />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 card border-0 p-3">
          <TableOrdersReceivable orders={orders} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrdersReceivable;
