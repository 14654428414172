import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import * as formik from 'formik';
import { FieldArray, Field, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import axiosClient from '../../../../../config/axios.js';

function TypeEvidencesFields({handleChange, values, errors, setFieldValue }) {

    const [orderStatuses, setOrderStatuses] = useState([]);

    const fetchOrderStatuses = async () => {
        axiosClient.get(`/orderStatuses/all`, {
            params: {
                payment_type_code: 'CONTADO/CREDITO'
            }
        }).then(response => {
            const formattedOptions = response.data.data.map(orderStatus => ({
                value: orderStatus.id,
                label: orderStatus.order_status,
                code: orderStatus.order_status_code
            }));
            setOrderStatuses(formattedOptions)
           // console.log(formattedOptions);
        }).catch(error => {
            console.error('Error fetching orderStatuses:', error);
        });
    };

    useEffect(() => {
        fetchOrderStatuses();
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Tipo de evidencia</Form.Label>
                    <Form.Control
                        type="text"
                        name="evidence"
                        placeholder="Tipo de Evidencia"
                        value={values.evidence}
                        onChange={handleChange}
                        isInvalid={!!errors.evidence}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.evidence}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12">
                    <Form.Label>Evidencia requerida en los siguientes estatus</Form.Label>
                    <Select
                        name="order_status"
                        options={orderStatuses}
                        className={errors.order_status ? 'is-invalid' : ''}
                        placeholder="Selecciona uno o más estatus"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        isClearable={true}
                        isSearchable={true}
                        value={values.order_status}
                        isMulti
                        onChange={(e) => {
                            setFieldValue("order_status", e ? e : '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.order_status}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>

        </>
    );
};

export default TypeEvidencesFields;