import React, { useEffect, useRef } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Tooltip } from 'react-tooltip';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import ResumeStatusPayment from './resumeStatusPayment';
import TablePayments from './tablePayments';
import TableInvoices from './tableInvoices';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import { useAuth } from '../../../../context/AuthContext';
 
const PaymentsAndInvoices = React.forwardRef(({ paymentTypes, tablePaymentsContadoRef, tablePaymentsCreditoRef }, ref) => {

    const { isAccountant, isAdmin } = useAuth()

    //function PaymentsAndInvoices({ paymentTypes, tablePaymentsContadoRef, tablePaymentsCreditoRef }) {
    const { values, setFieldValue } = useFormikContext();

    function formatNumberWithCommas(number) {
        if (number) {
            // const roundedNumber = parseFloat(number);
            const roundedNumber = parseFloat(number).toFixed(2);
            return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return '';
    }

    useEffect(() => {
        const globalPayments = (values.payments_contado || []).concat(values.payments_credito || []);

        let globalTotalPayed = globalPayments.reduce((total, payment) => {
            if (isNaN(payment.payment_amount) || payment.payment_amount === "") {
                //isValid = false;
                return total; // No agregamos nada al total
            }
            return total + Number(payment.payment_amount);
        }, 0);

        //globalTotalPayed = globalTotalPayed?.toFixed(2);

        let contadoTotalPayed = values.payments_contado?.reduce((total, payment) => {
            if (isNaN(payment.payment_amount) || payment.payment_amount === "") {
                //isValid = false;
                return total; // No agregamos nada al total
            }
            return total + Number(payment.payment_amount);
        }, 0);
        //contadoTotalPayed = contadoTotalPayed?.toFixed(2);

        let creditoTotalPayed = values.payments_credito?.reduce((total, payment) => {
            if (isNaN(payment.payment_amount) || payment.payment_amount === "") {
                //isValid = false;
                return total; // No agregamos nada al total
            }
            return total + Number(payment.payment_amount);
        }, 0);
        //creditoTotalPayed = creditoTotalPayed?.toFixed(2);

        setFieldValue("globalTotalPayed", globalTotalPayed);
        setFieldValue("contadoTotalPayed", contadoTotalPayed);
        setFieldValue("creditoTotalPayed", creditoTotalPayed);


        setFieldValue("totalPendingToPay", (Number(values.subtotal).toFixed(2) - Number(globalTotalPayed).toFixed(2)));
        //setFieldValue("totalPendingToPay", (Number(values.total) - Number(globalTotalPayed)).toFixed(2));





        if (values.excedente_asegurado !== null && values.excedente_asegurado !== 0) {
            let totalPendingExcedente = (Number(values.excedente_asegurado) - Number(contadoTotalPayed));
            if (totalPendingExcedente < 0) {
                totalPendingExcedente = 0;
            }
            setFieldValue("totalPendingExcedente", totalPendingExcedente);

        } else {
            setFieldValue("totalPendingExcedente", null);
        }


    }, [values.payments_contado, values.payments_credito, values.invoices_contado, values.invoices_credito, values.excedente_asegurado, values.total, setFieldValue]);

    useEffect(() => {


        const globalInvoices = (values.invoices_contado || []).concat(values.invoices_credito || []);

        let globalTotalInvoice = globalInvoices.reduce((total, invoice) => {
            if (isNaN(invoice.invoice_amount) || invoice.invoice_amount === "") {
                //isValid = false;
                return total; // No agregamos nada al total
            }
            return total + Number(invoice.invoice_amount);
        }, 0);
        //globalTotalInvoice = globalTotalInvoice?.toFixed(2);

        let contadoTotalInvoice = values.invoices_contado?.reduce((total, invoice) => {
            const hasRetention = invoice.invoice_retention;
            const amount = Number(invoice.invoice_amount);
            const iva = amount * 0.16;
            const totalRetention = iva * 0.25;
            if (isNaN(invoice.invoice_amount) || invoice.invoice_amount === "") {
                //isValid = false;
                return total; // No agregamos nada al total
            }
            const totalOrder = hasRetention ? amount + iva - totalRetention : amount + iva;
            return total + totalOrder;
        }, 0);
        //contadoTotalInvoice = contadoTotalInvoice?.toFixed(2);

        let creditoTotalInvoice = values.invoices_credito?.reduce((total, invoice) => {
            const hasRetention = invoice.invoice_retention;
            const amount = Number(invoice.invoice_amount);
            const iva = amount * 0.16;
            const totalRetention = iva * 0.25;

            if (isNaN(invoice.invoice_amount) || invoice.invoice_amount === "") {
                //isValid = false;
                return total; // No agregamos nada al total
            }
            
            const totalOrder = hasRetention ? amount + iva - totalRetention : amount + iva;
            return total + totalOrder;
        }, 0);
        //creditoTotalInvoice = creditoTotalInvoice?.toFixed(2);

        setFieldValue("globalTotalInvoice", globalTotalInvoice);
        setFieldValue("contadoTotalInvoice", contadoTotalInvoice);
        setFieldValue("creditoTotalInvoice", creditoTotalInvoice);

    }, [values.invoices_contado, values.invoices_credito, values.total, setFieldValue]);

    return (

        <Panel>
            <PanelHeader noButton={true}>Pagos y Facturación</PanelHeader>
            <ResumeStatusPayment />
            <div className="accordion" id="accordion"> 
                <div className="accordion-item border-0">
                    <div className="accordion-header" id="headingOne">
                        <button className="accordion-button bg-gray-900 text-white px-3 py-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                            <i className="fa fa-circle fa-fw text-blue me-2 fs-8px"></i> Pagos y Facturación de CONTADO
                            {values.contadoTotalPayed ? <span className="badge bg-success ms-2">PAGOS AL CONTADO: ${formatNumberWithCommas(values.contadoTotalPayed)}</span> : ``}
                            {values.contadoTotalInvoice ? <span className="badge bg-success ms-2">FACTURADO AL CONTADO: ${formatNumberWithCommas(values.contadoTotalInvoice)}</span> : ``}
                            {values.contadoTotalInvoice && values.contadoTotalPayed ? (values.contadoTotalPayed - values.contadoTotalInvoice > 1 ? <span className="badge bg-danger ms-2">POR FACTURAR: ${formatNumberWithCommas(values.contadoTotalPayed - values.contadoTotalInvoice)}</span> : (values.contadoTotalPayed - values.contadoTotalInvoice <= 1 ? <span className="badge bg-success ms-2">PAGOS COMPLETAMENTE FACTURADOS</span> : <span className="badge bg-warning ms-2">FACTURAS EXCEDIDAS POR: ${formatNumberWithCommas(values.contadoTotalPayed - values.contadoTotalInvoice)}</span>)) : ``}
                        </button>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordion">



                        <div className="accordion-body bg-gray-100">

                            <Row>
                                <Col md='12'>
                                    <h3>Contado</h3>
                                </Col>
                            </Row>

                            <Row>
                                {paymentTypes && (
                                    <>
                                        <h5 className="mt-10px"><i className="fa-solid fa-sack-dollar"></i> Pagos</h5>


                                        <TablePayments
                                            ref={tablePaymentsContadoRef}
                                            paymentTypeCode='CONTADO'
                                            valuesKey='payments_contado'
                                            disabled={Boolean(values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE' && !isAccountant())}
                                        />

                                        <h5 className="mt-10px"><i className="fa-solid fa-file-invoice"></i> Facturas</h5>
                                        <TableInvoices
                                            paymentTypeCode='CONTADO'
                                            valuesKey='invoices_contado'
                                            nomenclaturaInicial='AA'
                                            disabled={Boolean(values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE') && !isAccountant()}
                                        />
                                    </>
                                )}
                            </Row>
                            {/* <Form.Group as={Col} className="mt-3 ms-3"  >
                                                                    <Form.Check
                                                                        name="need_invoice"
                                                                        type="switch"
                                                                        label="Requiere Factura"
                                                                        checked={values.need_invoice}
                                                                        onChange={(e) => {
                                                                            //setFieldTouched("contado", true);
                                                                            setFieldValue("need_invoice", e.target.checked);
                                                                        }}
                                                                        //onBlur={handleBlur}
                                                                    />
                                                                </Form.Group> */}
                        </div>
                    </div>
                </div>
                <div className="accordion-item border-0">
                    <div className="accordion-header" id="headingTwo">
                        <button className="accordion-button bg-gray-900 text-white px-3 pt-10px pb-10px pointer-cursor collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                            <i className="fa fa-circle fa-fw text-indigo me-2 fs-8px"></i> Pagos y Facturación a CRÉDITO
                            {values.creditoTotalPayed ? <span className="badge bg-success ms-2">PAGOS A CRÉDITO: ${formatNumberWithCommas(values.creditoTotalPayed)}</span> : ``}
                            {values.creditoTotalInvoice ? <span className="badge bg-success ms-2">FACTURADO A CRÉDITO: ${formatNumberWithCommas(values.creditoTotalInvoice)}</span> : ``}
                            {values.creditoTotalInvoice && values.creditoTotalPayed ? (values.creditoTotalPayed - values.creditoTotalInvoice > 1 ? <span className="badge bg-danger ms-2">POR FACTURAR: ${formatNumberWithCommas(values.creditoTotalPayed - values.creditoTotalInvoice)}</span> : (values.creditoTotalPayed - values.creditoTotalInvoice <= 1 ? <span className="badge bg-success ms-2">PAGOS COMPLETAMENTE FACTURADOS</span> : <span className="badge bg-warning ms-2">FACTURAS EXCEDIDAS POR: ${formatNumberWithCommas(values.creditoTotalPayed - values.creditoTotalInvoice)}</span>)) : ``}
                        </button>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion">
                        <div className="accordion-body bg-gray-100">
                            <Row>
                                <Col md='12'>
                                    <h3>Crédito</h3>
                                </Col>
                            </Row>

                            <Row>
                                <Col md='12'>
                                    {paymentTypes && (
                                        <>
                                            <h5 className="mt-10px"><i className="fa-solid fa-file-invoice"></i> Facturas</h5>
                                            <TableInvoices
                                                paymentTypeCode='CREDITO'
                                                valuesKey='invoices_credito'
                                                nomenclaturaInicial='BB'
                                                disabled={Boolean(values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE' )&& !isAccountant() }
                                            />
                                            <h5 className="mt-10px"><i className="fa-solid fa-sack-dollar"></i> Pagos</h5>
                                            <TablePayments
                                                ref={tablePaymentsCreditoRef}
                                                paymentTypeCode='CREDITO'
                                                valuesKey='payments_credito'
                                                disabled={Boolean(values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE') && !isAccountant() }
                                            />

                                        </>
                                    )}
                                </Col>

                            </Row>

                            
                        </div>
                    </div>
                </div>
            </div>
        </Panel >


    );
});


export default PaymentsAndInvoices;