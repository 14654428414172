import React from "react";
import CustomInput from "../components/formik/CustomInput";
import CustomSelect from "../components/formik/CustomSelect";

const OrderReceivableForm = ({ formik }) => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-between">
        <div className="col-md-8 row">
          <CustomInput
            label="Fecha inicio"
            field="start"
            type="date"
            formik={formik}
            sm={4}
          />
          <CustomInput
            label="Fecha fin"
            field="end"
            type="date"
            formik={formik}
            sm={4}
          />

          <CustomSelect
            label="Status"
            field="status"
            formik={formik}
            options={[
              {
                value: "all",
                label: "Todas",
              },
              {
                value: "por-cobrar",
                label: "Por cobrar",
              },
              {
                value: "cobradas",
                label: "Cobradas",
              },
            ]}
            required
            sm={4}
          />
        </div>
        <div className="col-md-4">
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "end",
              marginTop: 20,
            }}
          >
            <button
              className="btn btn-primary btn-lg"
              onClick={() => formik.handleSubmit()}
            >
              <i class="fa-solid fa-magnifying-glass" />
              &nbsp;Buscar
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderReceivableForm;
