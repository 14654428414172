import React, { useState, useEffect, useCallback } from 'react';

import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import { FieldArray } from 'formik';
import OrderLocationField from './orderLocationField';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer, InfoWindow, Polygon, LoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import 'animate.css/animate.min.css';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import axiosClient from '../../../../config/axios.js';
import axios from 'axios';
import { ServiceConact } from '../../../../components/reports/ServiceColumn.js';
import { useAuth } from '../../../../context/AuthContext.js';


function MapMultipleDestination({ values, touched, errors, setFieldValue, setFieldTouched, tiposLocalizacionServicio, modalidadesTarifa }) {
    const { id } = useParams();
    // console.log('los datos de valies es ', values)
    const { isAdmin } = useAuth();
    const mapRef = React.createRef();
    const [cuadroBusquedaResultados, setCuadroBusquedaResultados] = useState([]);
    const [localizacionTarifasEncontradas, setLocalizacionTarifasEncontradas] = useState([]);
    const [localizacionTarifaClicked, setLocalizacionTarifaClicked] = useState([]);
    const [localizacionNearbyClicked, setLocalizacionNearbyClicked] = useState([]);
    const [directions, setDirections] = useState([]);
    const [showDirections, setShowDirections] = useState(false);
    const [distance, setDistance] = useState(null);
    const [duration, setDuration] = useState(null);
    const [minimumLocations, setMinimumLocations] = useState(0);
    const [localRateMapCoordinates, setLocalRateMapCoordinates] = useState([]);
    const [programaticallyEnableEditLocationsFinished, setProgramaticallyEnableEditLocationsFinished] = useState(false);
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;



    const center = { lat: 17.0731842, lng: -96.7265889 };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey
    })

    const [map, setMap] = useState(null);

    const onLoad = useCallback((map) => {
        setMap(map)

        if (values.order_locations) {
            handleCenterMapByLocations(map, values.order_locations);
        } else {
            const bounds = new window.google.maps.LatLngBounds(center);
            map.fitBounds(bounds);
            map.setZoom(11);
        }
    }, []);

    /*
    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, []);
*/
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);




    const getFormattedAddress = async (lat, lon) => {
        if (lat && lon) {
            try {
                const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
                return response.data.display_name;
            } catch (error) {
                console.error('Error fetching address:', error);
                return null;
            }
        } else {
            console.error('Error fetching address. Lat or lon should not be null');
            return null;
        }

    };

    const getLocalRateMapCoordinates = () => {
        axiosClient.get(`/rates/getLocalRateMapCoordinates`).then(response => {
            if (response?.data?.length > 0) {
                const newData = response.data.map(item => ({
                    lat: parseFloat(item.lat),
                    lng: parseFloat(item.lng)
                }));
                setLocalRateMapCoordinates(newData);
            }
        }).catch(error => {
            console.error('Error fetching order data:', error);
        });
    }

    const polygonOptions = {
        paths: localRateMapCoordinates,
        strokeColor: '#0000FF',
        strokeOpacity: 0.3,
        strokeWeight: 2,
        fillColor: '#0000FF',
        fillOpacity: 0.07,
    };


    const handleCenterMapByLocations = (map, locations) => {
        const bounds = new window.google.maps.LatLngBounds();
        let validLocations = 0;
        locations.forEach((location) => {
            if (location && location.latitude && location.longitude) {
                bounds.extend({
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude)
                });
                validLocations += 1;
            }
        });

        if (validLocations > 0) {
            map.fitBounds(bounds);

            if (validLocations === 1) {
                map.setZoom(14);
            }
        }

    }

    const handleSearchAddress = async (indexNumber) => {

        if (values.order_locations && values.order_locations.length > indexNumber && values.order_locations[indexNumber].cuadro_busqueda) {
            try {

                setFieldValue(`order_locations.${indexNumber}.latitude`, null);
                setFieldValue(`order_locations.${indexNumber}.longitude`, null);
                setFieldValue(`order_locations.${indexNumber}.location_rate_id`, null);
                setFieldValue(`order_locations.${indexNumber}.referencia`, "");
                setFieldValue(`order_locations.${indexNumber}.direccion`, "");


                const coordinateRegex = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
                const isCoordinate = coordinateRegex.test(values.order_locations[indexNumber].cuadro_busqueda);
                if (isCoordinate) {
                    const [newLatitude, newLongitude] = values.order_locations[indexNumber].cuadro_busqueda.split(',').map((coord) => parseFloat(coord.trim()));
                    const address = {
                        index: indexNumber,
                        latitude: newLatitude,
                        longitude: newLongitude
                    }
                    handleSelectAddress(address);

                } else {
                    const results = await getGeocode({ address: values.order_locations[indexNumber].cuadro_busqueda });
                    addNotification('success', 'Resultados Encontrados', `Se ha${results.length > 1 ? 'n' : ''} encontrado ${results.length} resultado${results.length > 1 ? 's' : ''}  de la búsqueda realizada.`, 'top-right');

                    //console.log("datos encontrados", results);
                    const latLngArray = results.map(address => ({
                        latitude: address.geometry.location.lat(),
                        longitude: address.geometry.location.lng(),
                        index: indexNumber
                    }));
                    setCuadroBusquedaResultados(latLngArray);
                    handleCenterMapByLocations(map, latLngArray);

                }

            } catch (error) {
                addNotification('danger', 'Ups!', 'No se ha encontrado algún resultado. Favor de realizar nuevamente la búsqueda.', 'top-right');
                console.error('Error fetching coordinates:', error);
            }
        }
    }

    const handleSelectAddress = async (address) => {
        //console.log("handleSelectAddress");
        if (address) {

            setFieldValue(`order_locations.${address.index}.index`, address.index);
            setFieldValue(`order_locations.${address.index}.latitude`, address.latitude);
            setFieldValue(`order_locations.${address.index}.longitude`, address.longitude);

            const formattedAddress = await getFormattedAddress(address.latitude, address.longitude);
            setFieldValue(`order_locations.${address.index}.direccion`, formattedAddress ? formattedAddress : '');

            setCuadroBusquedaResultados([]);
            handleCenterMapByLocations(map, [{
                latitude: address.latitude,
                longitude: address.longitude
            }]);

            //console.log(address.latitude, address.longitude);

            
            const longitude = parseFloat(address.longitude);
            const latitude = parseFloat(address.latitude);
            const radius = 10000;

           // console.log('los datos de adrress ', address)
            // Realizar la solicitud GET
            
            
            axiosClient.get(`/rates/isCoordinateInsideLocalRatePolygon`, {
                params: {
                    coordinate: { lat: address.latitude, lng: address.longitude }
                }
            }).then(response => {
                setFieldValue(`order_locations.${address.index}.inside_polygon_local_rate`, response?.data?.insidePolygon);
                //console.log("response 1", response?.data?.insidePolygon);
            }).catch(error => {
                console.error('Error fetching order data:', error);
            });

            if (values.service_location_type_id?.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false) {
                fetchClosestLocations({
                    index: address.index,
                    latitude: address.latitude,
                    longitude: address.longitude,
                });
            }

        }
    }

    const editFlagForeignRate = (indexNumber) => {
        //console.log("indexNumber", values.order_locations[indexNumber]);

        fetchClosestLocations({
            index: indexNumber,
            latitude: values.order_locations[indexNumber].latitude,
            longitude: values.order_locations[indexNumber].longitude,
        });
    }

    const handleMarkerDragEndOrderLocation = async (index, event) => {
        setFieldValue(`order_locations.${index}.latitude`, event.latLng.lat());
        setFieldValue(`order_locations.${index}.longitude`, event.latLng.lng());
        setFieldValue(`order_locations.${index}.location_rate_id`, null);

        const formattedAddress = await getFormattedAddress(event.latLng.lat(), event.latLng.lng());
        setFieldValue(`order_locations.${index}.direccion`, formattedAddress ? formattedAddress : '');

        if (values.service_location_type_id?.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false) {
            fetchClosestLocations({
                index,
                latitude: event.latLng.lat(),
                longitude: event.latLng.lng()
            });
        }

        axiosClient.get(`/rates/isCoordinateInsideLocalRatePolygon`, {
            params: {
                coordinate: { lat: event.latLng.lat(), lng: event.latLng.lng() }
            }
        }).then(response => {
            //console.log("response 2", response?.data?.insidePolygon);
            setFieldValue(`order_locations.${index}.inside_polygon_local_rate`, response?.data?.insidePolygon);
        }).catch(error => {
            console.error('Error fetching order data:', error);
        });

    };

    const fetchClosestLocations = async (location) => {
        try {
            const response = await axiosClient.get(`/locations/findClosestLocations`, {
                headers: {
                    Authorization: `Bearer `
                },
                params: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                },
            });
            const newResponse = response.data.map((item) => ({
                ...item,
                index: location.index,
            }));

            setLocalizacionTarifasEncontradas(newResponse);

        } catch (error) {
            console.error(error);
        }
    };

    const handleLocalizacionTarifaSelected = (location) => {
        setLocalizacionTarifasEncontradas([]);
        setLocalizacionTarifaClicked(null);
        setFieldValue(`order_locations.${location.index}.location_rate_id`, location);
    }

    const handleShowRoute = () => {
        if (values.order_locations && values.order_locations.length >= 2) {
            const directionsService = new window.google.maps.DirectionsService();

            // Crear un arreglo de waypoints a partir de tus ubicaciones
            let waypoints = values.order_locations.map((location, index) => (index !== 0 && index !== values.order_locations.length - 1 ? {
                location: new window.google.maps.LatLng(location.latitude, location.longitude),
                stopover: true, // Indica que se detendrá en cada waypoint
            } : null));
            waypoints = waypoints.filter((location) => (location !== null));

            directionsService.route(
                {
                    origin: new window.google.maps.LatLng(values.order_locations[0].latitude, values.order_locations[0].longitude), // Origen en la primera ubicación
                    destination: new window.google.maps.LatLng(values.order_locations[values.order_locations.length - 1].latitude, values.order_locations[values.order_locations.length - 1].longitude), // Destino en la última ubicación
                    waypoints: waypoints,
                    travelMode: 'DRIVING', // Puedes cambiar el modo de viaje según tus necesidades
                },
                (result, status) => {
                    if (status === 'OK') {
                        const route = result.routes[0];
                        const distance = parseFloat((route.legs.reduce((total, leg) => total + leg.distance.value, 0) / 1000).toFixed(1));
                        const duration = route.legs.reduce((total, leg) => total + leg.duration.value, 0);


                        setDistance(distance);
                        setDuration(formatDuration(duration));
                        setDirections(result);
                        setShowDirections(true);
                    } else {
                        console.error('Error al obtener direcciones:', status);
                    }
                }
            );
        } else {
            eraseRoutes();
        }

    };

    const eraseRoutes = () => {
        setDistance(null);
        setDuration(null);
        setDirections([]);
        setShowDirections(false);
    }
    

    useEffect(() => {
        if (!id && values.edit_locations_finished) {
            //handleShowRoute(); quitar
        } else {
            eraseRoutes();
        }

        if (touched.edit_locations_finished && values.edit_locations_finished && values.montacarga !== true) {

            let some_inside_polygon_local_rate_is_null = values.order_locations.some(item => item.inside_polygon_local_rate === null);
            if (!some_inside_polygon_local_rate_is_null) {
                let is_there_foreign_locations = values.order_locations.some(item => !item.inside_polygon_local_rate);

                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: "btn btn-danger"
                    },
                    buttonsStyling: false
                });

                if (is_there_foreign_locations && values.service_location_type_id.service_location_code === 'LOCAL') {
                    //console.log("Al menos uno es foráneo y se seleccionó local");
                    swalWithBootstrapButtons.fire({
                        title: "Ups!",
                        text: "Al menos uno de los puntos se encuentra fuera de la zona configurada como Tarifa Local y se ha seleccionado este tipo de Tarifa. ¿Deseas cambiar a Tarifa Foránea?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Sí, cambiar a Tarifa Foránea",
                        cancelButtonText: "No, deseo mantener la Tarifa Local",
                        reverseButtons: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            addNotification('success', 'Tipo de Tarifa modificado', 'Se ha cambiado la orden a Tarifa Foránea', 'top-right');
                            setFieldValue("service_location_type_id", tiposLocalizacionServicio.find(item => item.service_location_code === "FORANEO"));
                            setFieldValue("edit_locations_finished", false);
                            setProgramaticallyEnableEditLocationsFinished(true);
                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            addNotification('warning', 'Tipo de Tarifa no modificado', 'Se conserva la orden como Tarifa Local', 'top-right');
                        }
                    });
                } else if (!is_there_foreign_locations && values.service_location_type_id.service_location_code === 'FORANEO') {
                    //console.log("Todos son locales y se seleccionó foráneo");

                    swalWithBootstrapButtons.fire({
                        title: "Ups!",
                        text: "Todos los puntos se encuentran dentro de la zona configurada como Tarifa Local y en tipo de tarifa seleccionaste Tarifa Foránea. ¿Deseas cambiar a Tarifa Local?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Sí, cambiar a Tarifa Local",
                        cancelButtonText: "No, deseo mantener la Tarifa Foránea",
                        reverseButtons: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            addNotification('success', 'Tipo de Tarifa modificado', 'Se ha cambiado la orden a Tarifa Local', 'top-right');
                            //console.log("tiposLocalizacionServicio", tiposLocalizacionServicio);
                            setFieldValue("service_location_type_id", tiposLocalizacionServicio.find(item => item.service_location_code === "LOCAL"));
                            setFieldValue("order_locations", values.order_locations.map(obj => ({ ...obj, location_rate_id: null })));
                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            addNotification('warning', 'Tipo de Tarifa no modificado', 'Se conserva la orden como Tarifa Foránea', 'top-right');
                        }
                    });
                }
            }




        }

    }, [values.edit_locations_finished]);

    useEffect(() => {
        if (!id && !values.edit_locations_finished && !programaticallyEnableEditLocationsFinished) { //si es nuevo y no se han terminado de editar las localizaciones, se debe reiniciar el arreglo de ubicaciones
            setFieldValue('order_locations', [
                {
                    id: "",
                    index: "",
                    cuadro_busqueda: "",
                    direccion: "",
                    referencia: "",
                    latitude: null,
                    longitude: null,
                    location_rate_id: null,
                    service_location_code: '',
                    removable: false,
                    inside_polygon_local_rate: null
                }
            ]);
        }
        if (localRateMapCoordinates.length === 0) {
            getLocalRateMapCoordinates();
        }

        if (values.grua || values.corresponsalia || values.taxi) {
            setMinimumLocations(2);
        } else if (values.auxilio_vial || values.montacarga) {
            setMinimumLocations(1);
        }
        setProgramaticallyEnableEditLocationsFinished(false);

        if (touched.service_location_type_id) {
            setFieldValue("manejar_tarifas_establecidas", null);
        }

    }, [values.service_location_type_id]);


    useEffect(() => {
        if (values.manejar_tarifas_establecidas === true) {
            let order_locations_aux = values.order_locations;
            order_locations_aux = order_locations_aux.map(obj => {
                return { ...obj, location_rate_id: null };
            });
            setFieldValue('order_locations', order_locations_aux);
            setLocalizacionTarifasEncontradas([]);
        }
    }, [values.manejar_tarifas_establecidas]);

    const formatDuration = (duracionEnSegundos) => {
        if (duracionEnSegundos < 3600) {
            const minutos = Math.floor(duracionEnSegundos / 60);
            return `${minutos} minutos`;
        } else if (duracionEnSegundos < 86400) {
            const horas = Math.floor(duracionEnSegundos / 3600);
            const minutos = Math.floor((duracionEnSegundos % 3600) / 60);
            return `${horas} horas y ${minutos} minutos`;
        } else {
            const dias = Math.floor(duracionEnSegundos / 86400);
            const horas = Math.floor((duracionEnSegundos % 86400) / 3600);
            const minutos = Math.floor(((duracionEnSegundos % 86400) % 3600) / 60);
            return `${dias} días, ${horas} horas y ${minutos} minutos`;
        }
    }

    function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        /*if (notificationContent) {
            notificationContent = (
                <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
                    <img src="../assets/img/user/user-12.jpg" width="52" alt="" className="rounded" />
                    <div className="flex-1 ps-2">
                        <h6 className="mb-1">Christopher Struth</h6>
                        <p className="mb-0">Bank Transfer</p>
                    </div>
                </div>
            );
        }*/

        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            animationIn: ["animate__animated", "animate__bounceInRight"],
            container: notificationPosition,
            animationOut: ["animate__animated", "animate__bounceOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true,
                waitForAnimation: true,
                showIcon: true
            },
            //dismissable: { timeout: true },
            //content: notificationContent
        });
    }

    const handleMapRightClick = async (e) => {
        if (!values?.edit_locations_finished) {
            //console.log("clic derecho", e);

            const newMarkerConfirmed = {
                id: "",
                index: "",
                cuadro_busqueda: e.latLng.lat() + ', ' + e.latLng.lng(),
                direccion: "",
                referencia: "",
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng(),
                location_rate_id: null,
                service_location_code: '',
                removable: true,
                inside_polygon_local_rate: null
            };


            if (values.order_locations.length > 0) {
                // Obtener el último objeto del arreglo
                const ultimoObjeto = values.order_locations[values.order_locations.length - 1];

                if (!ultimoObjeto.latitude) {

                    newMarkerConfirmed.index = (values.order_locations.length - 1);

                    let arreglo = values.order_locations;
                    arreglo.pop();
                    arreglo.push(newMarkerConfirmed);

                    setFieldValue('order_locations', arreglo);
                } else {
                    newMarkerConfirmed.index = values.order_locations.length;
                    setFieldValue('order_locations',
                        [...values.order_locations, newMarkerConfirmed]
                    );
                }
            } else {
                setFieldValue('order_locations',
                    [newMarkerConfirmed]
                );
            }

            handleSelectAddress(newMarkerConfirmed);
        }


    };
    const navigate = useNavigate();

    return (
        <>
            <ReactNotifications />

            {values.montacarga !== true && (
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" >
                        <Form.Label>Modalidad de Servicio</Form.Label>
                        <Select
                            name='service_location_type_id'
                            options={tiposLocalizacionServicio}
                            className={errors.service_location_type_id ? 'is-invalid' : ''}
                            placeholder="Selecciona la Modalidad del servicio"
                            isClearable={true}
                            isSearchable={true}
                            value={values.service_location_type_id}
                            onChange={(e) => {
                                setFieldValue("service_location_type_id", e ? e : '');
                            }}
                            isDisabled={values.edit_locations_finished && !isAdmin()}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.service_location_type_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            )}


            {(values.service_location_type_id.service_location_code === 'FORANEO') && (
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" >
                        <Form.Label>Modalidad de tarifa</Form.Label>
                        <Select
                            name='manejar_tarifas_establecidas'
                            options={modalidadesTarifa}
                            className={errors.manejar_tarifas_establecidas ? 'is-invalid' : ''}
                            placeholder="Selecciona la Modalidad de tarifa"
                            isClearable={true}
                            isSearchable={true}
                            value={modalidadesTarifa.find(e => e.value === values.manejar_tarifas_establecidas)}
                            onChange={(e) => {
                                setFieldTouched("manejar_tarifas_establecidas", true);
                                setFieldValue("manejar_tarifas_establecidas", e ? e.value : null);
                            }}
                            isDisabled={values.edit_locations_finished && !isAdmin()}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.manejar_tarifas_establecidas}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            )}


            {((values.service_location_type_id && (values.service_location_type_id.service_location_code === 'LOCAL' || values.manejar_tarifas_establecidas !== null)) || values.montacarga === true) && (
                <>
                    <Row>
                        <Col md="4">
                            <FieldArray
                                name="order_locations"
                                render={arrayHelpers => {
                                    const order_locations = values.order_locations;
                                    return (
                                        <>

                                            <Row className='timeline-locations'>
                                                <div className="timeline">
                                                    {order_locations && order_locations?.length > 0
                                                        ? order_locations.map((orderLocation, index) => (
                                                            <OrderLocationField
                                                                key={`order_location_field_${index} `}
                                                                indexNumber={index}
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                                arrayHelpers={arrayHelpers}
                                                                handleSearchAddress={handleSearchAddress}
                                                                editFlagForeignRate={editFlagForeignRate}
                                                            />
                                                        ))
                                                        : null
                                                    }

                                                </div>
                                            </Row>


                                            {!values.edit_locations_finished /*&& values.montacarga !== true*/ && (
                                                <Row className='mb-3'>
                                                    <div className="col-md-12">
                                                        <div className="d-flex align-items-center float-end">
                                                            <div >
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success btn-icon btn-circle btn-sm "

                                                                    onClick={() =>
                                                                        arrayHelpers.push({
                                                                            id: "",
                                                                            index: "",
                                                                            cuadro_busqueda: "",
                                                                            direccion: "",
                                                                            referencia: "",
                                                                            latitude: null,
                                                                            longitude: null,
                                                                            location_rate_id: null,
                                                                            service_location_code: '',
                                                                            removable: true,
                                                                            inside_polygon_local_rate: null
                                                                        })
                                                                    }
                                                                    //disabled={
                                                                       // (values.service_location_type_id?.service_location_code === 'FORANEO' && (values.manejar_tarifas_establecidas === true ? !values.order_locations.every(obj => obj.latitude !== null) : !values.order_locations.every(obj => obj.location_rate_id !== null)))
                                                                       // || (values.service_location_type_id?.service_location_code === 'LOCAL' && !values.order_locations.every(obj => obj.latitude !== null))
                                                                    //}
                                                                >
                                                                    <i className="fa-solid fa-plus"></i>
                                                                </button>

                                                            </div>
                                                            <div className='ms-1'>
                                                                Agregar destino
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Row>
                                            )}

                                            {(minimumLocations > values.order_locations.length
                                                || (values.service_location_type_id?.service_location_code === 'FORANEO' && (values.manejar_tarifas_establecidas === true ? !values.order_locations.every(obj => obj.latitude !== null) : !values.order_locations.every(obj => obj.location_rate_id !== null)))
                                                || (!values.order_locations.every(obj => obj.latitude !== null))
                                            ) ?
                                                (
                                                    <Row className='mt-4'>
                                                        <div className="col-md-12">
                                                            <div className="alert alert-warning alert-dismissible fade show mb-0">
                                                                {
                                                                    (values.service_location_type_id?.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false && !values.order_locations.every(obj => obj.location_rate_id !== null))
                                                                        ? <>Se debe buscar, confirmar y seleccionar la tarifa de todas las ubicaciones.</>
                                                                        : (!values.order_locations.every(obj => obj.latitude !== null))
                                                                            ? <>Se deben buscar y confirmar todas las ubicaciones.</>
                                                                            : (minimumLocations > values.order_locations.length
                                                                                ? <>Por el tipo de servicio seleccionado, debe haber al menos {minimumLocations === 1 ? 'una' : minimumLocations} ubicaci{minimumLocations === 1 ? 'ón' : 'ones'}.</>
                                                                                : <></>)


                                                                }

                                                            </div>
                                                        </div>
                                                    </Row>
                                                ) :
                                                <Row className='mt-5'>
                                                    <Form.Group as={Col} md="12" >
                                                        <Form.Check
                                                            name="edit_locations_finished"
                                                            type="switch"
                                                            label="He finalizado el registro de las ubicaciones"
                                                            checked={values.edit_locations_finished}
                                                            onChange={(e) => {
                                                                setFieldTouched('edit_locations_finished', true);
                                                                setFieldValue('edit_locations_finished', e.target.checked);
                                                            }}
                                                            disabled={
                                                                Boolean(minimumLocations > values.order_locations.length
                                                                    || (values.service_location_type_id?.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false && !values.order_locations[values.order_locations.length - 1].location_rate_id)
                                                                    || (values.service_location_type_id?.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === true && !values?.order_locations.every(obj => obj?.latitude !== null))
                                                                    || (values.service_location_type_id?.service_location_code === 'LOCAL' && !values.order_locations[values.order_locations.length - 1]?.latitude)
                                                                    || values.orderStatuses?.ENTREGA?.has_passed_status) && !isAdmin()
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Row>

                                            }



                                            {showDirections && directions && (
                                                <Row className='mt-4'>
                                                    <div className="alert alert-info alert-dismissible fade show mb-0">
                                                        <strong>Información de la ruta: </strong>{" "}Distancia de Origen a Destino final: {distance} km.; Ruta completa sin interrupciones: {duration}. Datos aproximados.
                                                    </div>
                                                </Row>
                                            )}

                                        </>
                                    );
                                }}
                            />
                        </Col >
                        <Col md="8">
                            <Row>
                                {isLoaded
                                    ?
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '600px'
                                        }}
                                        center={(cuadroBusquedaResultados && cuadroBusquedaResultados.length === 1
                                            ? { lat: parseFloat(cuadroBusquedaResultados[0].latitude), lng: parseFloat(cuadroBusquedaResultados[0].longitude) }
                                            : (values.order_locations && values.order_locations[values.order_locations.length - 1] && values.order_locations[values.order_locations.length - 1].latitude ? { lat: parseFloat(values.order_locations[values.order_locations.length - 1].latitude), lng: parseFloat(values.order_locations[values.order_locations.length - 1].longitude) } : { lat: 17.0731842, lng: -96.7265889 })
                                        )}
                                        zoom={11}
                                        onLoad={onLoad}
                                        onRightClick={handleMapRightClick}
                                        onUnmount={onUnmount}
                                    >

                                        <Polygon
                                            paths={localRateMapCoordinates}
                                            options={polygonOptions}
                                            onRightClick={handleMapRightClick}
                                        />



                                        {cuadroBusquedaResultados && cuadroBusquedaResultados.map((result, index) => (
                                            <Marker
                                                key={`cuadroBusquedaResultados_${index} `}
                                                position={{
                                                    lat: result.latitude,
                                                    lng: result.longitude
                                                }}
                                                onClick={() => handleSelectAddress(result)}
                                                icon={{
                                                    url: '/assets/img/varo/orders/markerQuestionMarkDestination.png', // Coloca aquí la URL de tu icono personalizado
                                                    scaledSize: new window.google.maps.Size(60, 60), // Tamaño del icono
                                                    Destination: new window.google.maps.Point(0, 0), // Origen del icono
                                                    anchor: new window.google.maps.Point(30, 60) // Punto de anclaje del icono
                                                }}
                                            />
                                        ))}

                                        {values.order_locations && values.order_locations.map((result, index) => (

                                            result.latitude && result.longitude && 
                                            <div>
                                                <Marker
                                                    key={`marker_order_locations_${index}`}
                                                    position={{
                                                        lat: parseFloat(result.latitude),
                                                        lng: parseFloat(result.longitude)
                                                    }}
                                                    //onClick={() => handleSelectAddress(result)}
                                                    icon={{
                                                        url: `/assets/img/varo/orders/markerLocation${index + 1}.png`, // Coloca aquí la URL de tu icono personalizado
                                                        scaledSize: new window.google.maps.Size(60, 60), // Tamaño del icono
                                                        origin: new window.google.maps.Point(0, 0), // Origen del icono
                                                        anchor: new window.google.maps.Point(30, 60) // Punto de anclaje del icono
                                                    }}
                                                    draggable={!values.edit_locations_finished} // Permite arrastrar el marcador
                                                    onDragEnd={(event) => handleMarkerDragEndOrderLocation(result.index, event)}
                                                />
                                                {/*result?.location_nearby?.map((data,dataIndex)=>(
                                                    <Marker
                                                    key={`marker_order_locations_${dataIndex}`}
                                                    position={{
                                                        lat: parseFloat(data.lat),
                                                        lng: parseFloat(data.lng)
                                                    }}
                                                    //onClick={() => handleSelectAddress(result)}
                                                    icon={{
                                                        url: `/assets/img/varo/orders/markerLocationBlue.png`, // Coloca aquí la URL de tu icono personalizado
                                                        scaledSize: new window.google.maps.Size(40, 40), // Tamaño del icono
                                                        origin: new window.google.maps.Point(0, 0), // Origen del icono
                                                        anchor: new window.google.maps.Point(20, 40) // Punto de anclaje del icono
                                                    }}
                                                    draggable={!values.edit_locations_finished} // Permite arrastrar el marcador
                                                    onClick={(event) => setLocalizacionNearbyClicked(data)}
                                                >
                                                    {localizacionNearbyClicked && localizacionNearbyClicked.id === data.id && (
                                                    <InfoWindow
                                                        onCloseClick={() => setLocalizacionNearbyClicked(null)}
                                                        visible={localizacionNearbyClicked.id === data.id}
                                                        anchor={new window.google.maps.Point(12, 40)}
                                                    >
                                                        <div className='text-center'>
                                                            <div>{'Detalle de la orden: '+ data?.folio}</div>
                                                            <div className='mb-1'>{'Modalidad de servicio: ' + data?.service_location_type_description} </div>
                                                            <div className='mb-1'>{'Tipo de servicio: ' + ServiceConact({row:data})} </div>
                                                            <div className='mb-1'>{'Costo sin iva: $' + data?.subtotal} </div>
                                                            <div>
                                                                <button type="button" className='btn btn-info btn-xs'
                                                                    onClick={() => {
                                                                        window.open(`/orders/edit/${data.order_id}`, '_blank', 'noopener,noreferrer');
                                                                    }}
                                                                >
                                                                    Detalle de la orden
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                                </Marker>
                                                ))*/}
                                            </div>
                                                
                                            
                                        ))}


                                        {localizacionTarifasEncontradas && localizacionTarifasEncontradas.map((location, index) => (
                                            <Marker
                                                key={`localizacionTarifasEncontradas_${index} `}
                                                position={{
                                                    lat: parseFloat(location.latitude),
                                                    lng: parseFloat(location.longitude)
                                                }}
                                                icon={{
                                                    url: `/assets/img/varo/orders/markerLocationBlue.png`, // Coloca aquí la URL de tu icono personalizado
                                                    scaledSize: new window.google.maps.Size(50, 50), // Tamaño del icono
                                                    Destination: new window.google.maps.Point(0, 0), // Origen del icono
                                                    anchor: new window.google.maps.Point(15, 50) // Punto de anclaje del icono
                                                }}
                                                onClick={() => setLocalizacionTarifaClicked(location)}
                                            >
                                                {localizacionTarifaClicked && localizacionTarifaClicked.id === location.id && (
                                                    <InfoWindow
                                                        onCloseClick={() => setLocalizacionTarifaClicked(null)}
                                                        visible={localizacionTarifaClicked.id === location.id}
                                                        anchor={new window.google.maps.Point(12, 40)}
                                                    >
                                                        <div className='text-center'>
                                                            <div>{location.location_name}</div>
                                                            <div className='mb-1'>{location.distance} km</div>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className='btn btn-info btn-xs'
                                                                    onClick={() => {
                                                                        //setFieldTouched('ubicacion_tarifaria_destino_id', true); // Marcar como tocado
                                                                        handleLocalizacionTarifaSelected(location);
                                                                    }}
                                                                >
                                                                    Seleccionar tarifa
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </InfoWindow>
                                                )}

                                            </Marker>
                                        ))}


                                        {values.order_locations && values.order_locations.map((result, index) => (
                                            result.location_rate_id && (
                                                <Marker
                                                    key={`order_locations_${index} `}
                                                    position={{
                                                        lat: parseFloat(result.location_rate_id.latitude),
                                                        lng: parseFloat(result.location_rate_id.longitude)
                                                    }}
                                                    icon={{
                                                        url: `/assets/img/varo/orders/markerTarifa${result.index + 1}.png`, // Coloca aquí la URL de tu icono personalizado
                                                        scaledSize: new window.google.maps.Size(50, 50), // Tamaño del icono
                                                        Destination: new window.google.maps.Point(0, 0), // Origen del icono
                                                        anchor: new window.google.maps.Point(15, 50) // Punto de anclaje del icono
                                                    }}
                                                //onClick={() => setLocalizacionTarifaClicked(location)}
                                                >

                                                </Marker>
                                            )
                                        ))}


                                        {showDirections && directions && (
                                            <DirectionsRenderer
                                                key='directions_renderer'
                                                directions={directions}
                                                options={{
                                                    polylineOptions: {
                                                        strokeColor: 'blue', // Color de la ruta
                                                    },
                                                }}
                                            />
                                        )}

                                    </GoogleMap>
                                    :
                                    <></>
                                }
                            </Row>


                            {values.montacarga !== true && (
                                <Row className='mt-3'>
                                    <div className="alert alert-success alert-dismissible fade show mb-0">
                                        <strong>Recuerda:</strong>{' '}
                                        El área remarcada en azul representa la zona donde el cobro corresponde a una tarifa local.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                                    </div>
                                </Row>
                            )}

                        </Col>
                    </Row >







                </>
            )
            }

        </>
    );
}

export default MapMultipleDestination;