import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { format } from "date-fns";

import { es } from "date-fns/locale";

import ServiceColumn from "../../../../components/reports/ServiceColumn";
import { formatNumberWithCommas } from "../../../../utils";
import { getOrderType } from "../../../../models/ReportOperatorSalary";
import notFund from './notFund.svg'
const TableOrdersNearby = ({ orders }) => {

  return (
    <div>
      {orders.map((order, index) => (
        <div key={index}>
          <div style={{fontWeight:'bold', fontSize:'16px'}}>Punto {index + 1}</div>
          <table className="table table-hover table-panel text-nowrap align-middle mb-0">
            <thead className="sticky-thead table-light text-center">
              <tr>
                <th className="align-middle">Folio</th>
                <th className="align-middle">Precio Sin Iva</th>
                <th className="align-middle">Fecha</th>
                <th className="align-middle">KM totales</th>
                <th className="align-middle">Precio por KM</th>
              </tr>
            </thead>

            <tbody>
              {order?.location_nearby?.length >= 0 ?   order?.location_nearby?.map((location) => (
                <tr className="text-center">
                  <td>
                    <Row className="mb-2">
                      <Col md="12">
                      <a href={`/orders/edit/${location?.order_id}`} target="_blank" rel="noopener noreferrer">
                        {location.folio?.toString().padStart(5, "0")}
                      </a>
                      </Col>
                    </Row>
                  </td>
                  <td>${location.subtotal}</td>
                  <td>
                    <ServiceColumn row={order} />
                    {format(new Date(location?.fecha_hora_reporte), "dd/MMM/yyyy", {
                  locale: es,
                })}
                  </td>
                  <td>
                    {/*{format(new Date(order?.fecha_hora_reporte), "dd/MMM/yyyy", {
                  locale: es,
                })}*/}
                {location.totalDistance} KM
                  </td>
                  <td>${location.totalDistance !== 0 ? Number(location.subtotal / location.totalDistance).toFixed(2) : location.subtotal}</td>
                  {/*<td>{Number(order.totalDistance).toFixed(2)} km</td>
              <td>{formatName(order.operador)}</td>
              <td>${formatNumberWithCommas(getComission(order))}</td>
              <td>{getReason(order)}</td>*/}
                </tr>
              )): 
                <tr>
                  <td colSpan={20}>
                      <Row className="mb-2">
                        <Col md="12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                          <img src={notFund} style={{ marginRight: '10px' }} alt="No encontrado" />
                          <h5>Datos no encontrados</h5>
                        </Col>
                      </Row>
                    </td>
                </tr>}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default TableOrdersNearby;
