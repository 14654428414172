import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';


function EditBrand() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        brand_name: ''
    });

    useEffect(() => {
        axiosClient.get(`/brands/${id}`).then(response => {
            const {brand_name} = response.data;
            setInitialValues({ brand_name});
        }).catch(error => {
            console.error('Error fetching brand data:', error);
        });
    }, [id]);

  return (
    <div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/brands">Marcas</Link></li>
						<li className="breadcrumb-item active">Edición de Marca</li>
					</ol>
					<h1 className="page-header mb-0">Edición de Marca</h1>
				</div>
			</div>

			
		
			<div className="row mb-3">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>Edición de Marca</PanelHeader>
						<PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    brand_name: yup.string().required("Ingrese el nombre de la Marca"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/brands/${id}`, values).then( response => {
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'La marca se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/brandsModels');
                                    }).catch(err => {
                                        let message = '';
                                       // console.log(err);
                                        if(err.response.data.original.code === '23505'){
                                            message = 'El nombre de la marca ya existe. Ingresa uno diferente.';
                                        } else {
                                            message=err.response.data.message;
                                        }
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                   <Form noValidate onSubmit={handleSubmit}>
                                   <Row className="mb-3">
                                       <Form.Group as={Col} md="12" >
                                           <Form.Label>Nombre de la Marca</Form.Label>
                                           <Form.Control
                                               type="text"
                                               name="brand_name"
                                               placeholder="Nombre de la Marca"
                                               value={values.brand_name}
                                               onChange={handleChange}
                                               isInvalid={!!errors.brand_name}
                                           />
                                           <Form.Control.Feedback type="invalid">
                                               {errors.brand_name}
                                           </Form.Control.Feedback>
                                       </Form.Group>
                                       
                                   </Row>
                                   
                                   <Row className="mb-3">
                                       <Col md="12">
                                           <Button type="submit">Guardar Marca</Button>{' '}
                                           <Link to={'/catalogs/brandsModels'} className="btn btn-info">Cancelar</Link>
                                       </Col>
                                   </Row>
                               </Form>
                                )}
                            </Formik>
                            
						</PanelBody>
					</Panel>
				</div>
				
			</div>
					
			
		</div>
    
  );
}

export default EditBrand;