import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { FieldArray } from 'formik';
import { useAuth } from '../../../../context/AuthContext.js';


function OrderStatusAsignadaSinEnviarSeleccOperador({ values, errors, setFieldValue, setFieldTouched, operadores }) {

    const { isAdmin } = useAuth()

    const handleSwitchChange = (index, encargado) => {
        if (!encargado) {
            setFieldValue(`operadores.${index}.encargado`, false);
        } else {
            const newOperadores = values.operadores.map((operador, idx) => ({
                ...operador,
                encargado: idx === index,
            }));
            setFieldValue('operadores', newOperadores);
        }
    };

   
    return (
        <>
            <Form.Label>Operador{`${values.operadores && values.operadores.length > 1 ? 'es' : ''}`}</Form.Label>
            <Select
                name="operadores"
                options={operadores}
                className={errors.operadores ? 'is-invalid' : ''}
                placeholder="Selecciona uno o más operadores"
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                isClearable={true}
                isSearchable={true}
                value={values.operadores}
                isMulti
                onChange={(e) => {
                    setFieldValue("operadores", e ? e : '');
                }}
                isDisabled={Boolean(values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE') && !isAdmin()}
            />

            <Form.Control.Feedback type="invalid">
                {errors.operadores}
            </Form.Control.Feedback>

            {values?.operadores?.length > 1 && (
                <Row>

                    <FieldArray
                        name="operadores_array"
                        render={arrayHelpers => {
                            const operadores = values.operadores;
                            return (
                                <Col sm='12'>
                                    <Row className='mt-2'>
                                        <Col sm='1'>
                                            <label>Encargado</label>
                                        </Col>
                                    </Row>
                                    {operadores && operadores.length > 0
                                        ? operadores.map((operador, index) => (
                                            <Row className='mt-2' key={`row_activ_operador_${index}`}>
                                                <Col sm='1'>
                                                    <Form.Check
                                                        name={`operadores.${index}.encargado`}
                                                        type="switch"
                                                        label=""
                                                        checked={values.operadores[index].encargado}
                                                        onChange={(e) => {
                                                            setFieldTouched(`operadores.${index}.encargado`, true);
                                                            //setFieldValue(`operadores.${index}.encargado`, e.target.checked);
                                                            handleSwitchChange(index, e.target.checked);
                                                        }}
                                                        disabled={Boolean(values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE') && !isAdmin()}
                                                    />
                                                </Col>
                                                <Col sm='4'>
                                                    <label className="form-label col-form-label text-end float-end">{operador?.label}</label>
                                                </Col>
                                                <Col sm='7'>
                                                    <textarea
                                                        name={`operadores.${index}.actividad`}
                                                        className={`form-control ${errors.operadores && errors.operadores[index] && errors.operadores[index].actividad ? 'is-invalid' : ''}`}
                                                        placeholder='Actividad realizada por el operador en la presente Orden'
                                                        onChange={(e) => {
                                                            setFieldValue(`operadores.${index}.actividad`, e.target.value);
                                                        }}
                                                        value={values.operadores[index].actividad}
                                                        disabled={values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE'}

                                                    ></textarea>
                                                </Col>
                                            </Row>
                                        ))
                                        : null
                                    }

                                </Col>
                            )
                        }}
                    />
                </Row>
            )}

        </>
    );
}

export default OrderStatusAsignadaSinEnviarSeleccOperador;