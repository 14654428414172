import React from "react";

const ServiceColumn = ({ row }) => {
  const badges = [];

  if (row.grua) {
    badges.push(
      <span
        key="servicio"
        className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
      >
        Grúa
      </span>
    );
  }

  if (row.auxilio_vial) {
    badges.push(
      <span
        key="auxilio_vial"
        className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
      >
        Auxilio Vial
      </span>
    );
  }

  if (row.montacarga) {
    badges.push(
      <span
        key="montacarga"
        className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
      >
        Montacarga
      </span>
    );
  }

  if (row.corresponsalia) {
    badges.push(
      <span
        key="corresponsalia"
        className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
      >
        Corresponsalía
      </span>
    );
  }

  if (row.taxi) {
    badges.push(
      <span
        key="taxi"
        className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
      >
        Taxi
      </span>
    );
  }

  return <div>{badges}</div>;
};

export default ServiceColumn;

export const ServiceConact = ({ row }) => {
  const badges = [];

  if (row.grua) {
    badges.push("Grúa");
  }

  if (row.auxilio_vial) {
    badges.push("Auxilio Vial");
  }

  if (row.montacarga) {
    badges.push("Montacarga");
  }

  if (row.corresponsalia) {
    badges.push("Corresponsalía");
  }

  if (row.taxi) {
    badges.push("Taxi");
  }

  return badges.join(", ");
};

export const ServiceBackground = ({ row }) => {
  if (row.grua) {
    return "53ff00";
  }

  if (row.auxilio_vial) {
    return "00daff";
  }

  if (row.montacarga) {
    return "ff7100";
  }

  if (row.corresponsalia) {
    return "ce84ec";
  }

  if (row.taxi) {
    return "fff400";
  }

  return "c5c5c5";
};
