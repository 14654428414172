// filepath: /Users/jairoboss/_/Karimnot/gruasvaro/gruas-frontend/src/components/Notification.js
import Swal from 'sweetalert2';

const notification = (icon, title) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  Toast.fire({
    icon: icon,
    title: title
  });
};

export default notification;