import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import RoleFields from './roleFields';


function EditRole() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;


    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/settings/roles">Roles</Link></li>
                        <li className="breadcrumb-item active">Edición de Rol</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Rol</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Rol</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    role_name: yup.string().required("Ingrese el nombre del Rol"),
                                    categoryPermissions: yup.array().of(
                                        yup.object().shape({
                                            active: yup.boolean()
                                        })
                                    ),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/roles/${id}`, values).then(response => {
                                        
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'El rol se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/settings/roles');
                                    }).catch(err => {
                                        let message = '';
                                       // console.log(err);
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El nombre del rol ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    id: id,
                                    role_name: '',
                                    categoryPermissions: []
                                }}
                            >
                                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                                    <Form noValidate onSubmit={handleSubmit}>

                                        <RoleFields
                                            id={id}
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            touched={touched}
                                            errors={errors}
                                        />
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar cambios</Button>{' '}
                                                <Link to={'/settings/roles'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>

                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditRole;