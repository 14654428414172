import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';


function EditLocation() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        location_name: '',
        distance: ''
    });

    useEffect(() => {
        axiosClient.get(`/locations/${id}`).then(response => {
            const { location_name, distance } = response.data;
            setInitialValues({ location_name, distance });
        }).catch(error => {
            console.error('Error fetching location data:', error);
        });
    }, [id]);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/locations">Ubicaciones</Link></li>
                        <li className="breadcrumb-item active">Edición de Ubicación</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Ubicación</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Ubicación</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    location_name: yup.string().required("Ingrese el nombre de la Ubicación"),
                                    distance: yup.number("La distancia debe ser un número").required("Ingrese la distancia"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/locations/${id}`, values).then(response => {
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'La ubicación se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/locations');
                                    }).catch(err => {
                                        let message = '';
                                       // console.log(err);
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El nombre de la ubicación ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="8" >
                                                <Form.Label>Nombre de la Ubicación</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="location_name"
                                                    placeholder="Nombre de la Ubicación"
                                                    value={values.location_name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.location_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.location_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" >
                                                <Form.Label>Distancia (km.)</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        placeholder="Distancia"
                                                        aria-label="Distancia"
                                                        name="distance"
                                                        value={values.distance}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.distance}
                                                    />
                                                    <InputGroup.Text >km.</InputGroup.Text>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.distance}
                                                    </Form.Control.Feedback>
                                                </InputGroup>

                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Ubicación</Button>{' '}
                                                <Link to={'/catalogs/locations'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditLocation;