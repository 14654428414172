import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import TypeEvidencesFields from './typeEvidencesFields';
import axiosClient from '../../../../../config/axios.js';


function EditTypeEvidence() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        evidence: ''
    });

    const formatOrderStatus = (typeEvidenceOrderStatuses) => {
        const formatted = typeEvidenceOrderStatuses.map((item) => {
            return {
                value: item.OrderStatus.id,
                label: item.OrderStatus.order_status,
                code: item.OrderStatus.order_status_code,
            };
        });

        return formatted;
    }


    useEffect(() => {
        axiosClient.get(`/typeEvidences/${id}`).then(response => {
           // console.log("responseeeeee typeEvidences", response);
            const { evidence, TypeEvidenceOrderStatuses } = response.data;

            
            setInitialValues({
                evidence,
                order_status: formatOrderStatus(TypeEvidenceOrderStatuses),
            });
        }).catch(error => {
            console.error('Error fetching insurance data:', error);
        });
    }, [id]);
    
    
    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/typeEvidences">Tipos de Evidencia</Link></li>
                        <li className="breadcrumb-item active">Edición de Tipo de Evidencia</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Tipo de Evidencia</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Tipo de Evidencia</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    evidence: yup.string().required("Ingrese el nombre del Tipo de Evidencia"),
                                    insuranceContacts: yup.array().of(
                                        yup.object().shape({
                                            name: yup.string().required('Favor de escribir el nombre'),
                                            position: yup.string().required('Favor de escribir el puesto'),
                                            phone: yup.string().required('Favor de escribir el número telefónico de contacto'),
                                        })
                                    ),
                                    typeEvidences: yup.array().of(
                                        yup.object().shape({
                                            evidence: yup.object().required('Selecciona el Tipo de evidencia'),
                                        })
                                    ),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/typeEvidences/${id}`, values).then(response => {

                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'El Tipo de Evidencia se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/typeEvidences');
                                    }).catch(err => {
                                        let message = '';
                                       // console.log(err.response);
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: err.response.data.message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >


                                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (

                                    <Form noValidate onSubmit={handleSubmit}>

                                        <TypeEvidencesFields
                                            handleSubmit={handleSubmit}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            values={values}
                                            touched={touched}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar cambios</Button>{' '}
                                                <Link to={'/catalogs/typeEvidences'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>

                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditTypeEvidence;