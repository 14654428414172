import { getCurrentFortnight } from "./ReportOperatorSalary";

export const ReportOrderReceivable = () => {
  const { start, end } = getCurrentFortnight();

  return {
    start: start.toISOString().split("T")[0],
    end: end.toISOString().split("T")[0],
    status: "all",
  };
};
