import * as React from "react";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../../../components/panel/panel";
import { Form, Row, Col } from "react-bootstrap";
import axiosClient from "../../../../config/axios.js";
import "./ComponentToPrint.css";
import OrderFieldsDatosBienes from "../orderSections/orderFieldsDatosBienes";
import OrderVehicles from "../orderVehicles/orderVehicles";
import OrderVehiclesPrint from "./orderVehiclesPrint";
import OrderContactoPrint from "./orderContactoPrint";
import OrderUbicacionPrint from "./orderUbicacionPrint";
import OrderMontosPrint from "./orderMontosPrint";

import { format } from "date-fns";
import { es } from "date-fns/locale";

// export class QuickOrderToPrint extends React.PureComponent {

//     constructor(props) {
//         super(props);
//        // console.log('------------')
//        // console.log(props)
//        // console.log('------------')
//         this.state = {
//             checked: false,
//             orderData: null
//         };
//     }

//     canvasEl;

//     async componentDidMount() {
//         const { id } = this.props;
//         const { orderData } = this.props;

//         this.setState({ orderData: orderData });
//         //await this.getLastOrderStatusesGroupedByOrderStatus(id);

//     }

//     async getLastOrderStatusesGroupedByOrderStatus(order_id) {
//         try {
//             try {
//                 const response = await axiosClient.get(`/orders/getLastOrderStatusesGroupedByOrderStatus/${order_id}`);

//             } catch (error) {
//                 console.error(error);
//             }
//         } catch (error) {
//             console.error(error);
//         }
//     }

//     componentDidUpdate(prevProps) {
//         if (prevProps.orderData !== this.props.orderData) {
//             this.setState({ orderData: this.props.orderData });
//             //this.getLastOrderStatusesGroupedByOrderStatus(this.props.id);
//         }
//     }

//     handleCheckboxOnChange = () =>
//         this.setState({ checked: !this.state.checked });

//     setRef = (ref) => (this.canvasEl = ref);

//     getPageWidth = () => {
//         // Define el ancho de la página en milímetros
//         const pageWidth = '216mm'; // A4 paper size width

//         // Retorna el ancho de la página como estilo CSS
//         return { width: pageWidth };
//     };

//     getPageMargins = () => {
//         // Define los márgenes personalizados aquí
//         const marginTop = '10mm';
//         const marginRight = '5mm';
//         const marginBottom = '10mm';
//         const marginLeft = '5mm';

//         // Retorna las reglas de estilo CSS para los márgenes de la página
//         return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }

//         `;
//     };

//     render() {
//         const { orderData } = this.state;
//        // console.log('los datos que llegan son ', orderData)

//     }
// }

export const QuickOrderToPrint = React.forwardRef((props, ref) => {
    
  const [orderData, setOrderData] = React.useState(props?.orderData);
  const getPageWidth = () => {
    // Define el ancho de la página en milímetros
    const pageWidth = "216mm"; // A4 paper size width

    // Retorna el ancho de la página como estilo CSS
    return { width: pageWidth };
  };

  const getPageMargins = () => {
    // Define los márgenes personalizados aquí
    const marginTop = "10mm";
    const marginRight = "5mm";
    const marginBottom = "10mm";
    const marginLeft = "5mm";

    // Retorna las reglas de estilo CSS para los márgenes de la página
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }
        
        `;
  };

  React.useEffect(()=>{
    setOrderData(props?.orderData)
  },[props])

  return (
      <div style={getPageWidth()} className="m-auto" ref={ref}>
        <style>{getPageMargins()}</style>
        <div className="m-0">
          <Row className="mb-0 align-items-center">
            <div className="col-sm-8">
              <Row className="align-items-center m-0">
                <div className="col-sm-4">
                  <div className="text-center">
                    <img
                      alt="A test"
                      src="/assets/img/varo/logo.png"
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="offset-sm-2 col-sm-6">
                  <div className="text-center fw-bold">
                    CARTA DE SERVICIO/C. PORTE
                  </div>
                  <div className="text-center fw-bold text-red">
                    FOLIO:{" "}
                    {`No. ${orderData?.folio?.toString()?.padStart(5, "0")}`}
                  </div>
                </div>
              </Row>
              <Row className="align-items-center">
                <small className="mt-1 simple-text">
                  R.F.C. VABC-620921-AI9 BLvd. Eduardo Vasconcelos 207-D, Col.
                  Jalatlaco, Oaxaca, Oax.
                  <br></br>C.P. 68080 Tel./Fax. (951) 515-05-95 E-mail:
                  gruasvaro@prodigy.net.mx
                </small>
              </Row>
            </div>

            <div className="col-sm-4">
              <Row className="mb-0 mt-0 pb-0 pt-0">
                {/*orderData?.fecha_hora_cita && (
                                    <Col sm='12'>

                                        <div className="text-center fw-bold fs-14px">
                                            <span className="fa fa-clock me-1" />
                                            CITA: {' '}
                                            {orderData?.fecha_hora_cita && format(
                                                new Date(orderData.fecha_hora_cita),
                                                'EEEE dd/MMM/yyyy',
                                                { locale: es }
                                            )} {orderData?.fecha_hora_cita && format(
                                                new Date(orderData?.fecha_hora_cita),
                                                'hh:mm a',
                                                { locale: es }
                                            )}
                                        </div>
                                    </Col>
                                )*/}

                <Col sm="12">
                  <label
                    htmlFor="floatingInput"
                    className="d-flex align-items-center my-small-label-floating-input form-print"
                  >
                    ORDEN REGISTRADA EL
                  </label>

                  <div className="form-floating ">
                    <input
                      type="text"
                      className="form-control my-small-floating-input form-print"
                      id="floatingInput"
                      disabled
                      value={`${
                        orderData?.fecha_hora_reporte &&
                        format(
                          new Date(orderData.fecha_hora_reporte),
                          "dd/MMM/yyyy",
                          { locale: es }
                        )
                      } ${
                        orderData?.fecha_hora_reporte &&
                        format(
                          new Date(orderData?.fecha_hora_reporte),
                          "hh:mm a",
                          { locale: es }
                        )
                      } `}
                    />
                    {/*por ${orderData?.base_id ? orderData?.base_id.label : ''*/}
                  </div>
                </Col>
              </Row>
            </div>
          </Row>

          <Row className="mt-1 mb-1 ms-1 me-1">
            {[
              {
                label: "No. Folio",
                value: orderData?.folio
                  ?.toString()
                  ?.padStart(5, "0") /*orderData?.folio*/,
              },
              { label: "Chofer", value: orderData?.operadores?.[0]?.label },
              { label: "Grua", value: orderData?.crane_id?.label },
              { label: "Precio", value: orderData?.quick_order_price },
              { label: "Contado", value: orderData?.contado ? "Si" : "No" },
            ].map((item, index) => (
              <Col
                sm="12"
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "8px",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {item.label}:
                </label>
                <input
                  type="text"
                  style={{
                    flex: 1,
                    padding: "5px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    backgroundColor: "#f8f9fa",
                  }}
                  disabled
                  value={item.value || ""}
                />
              </Col>
            ))}

            <Col
              sm="12"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20%",
              }}
            >
              <div
                style={{
                  width: "300px",
                  height: "60px",
                  borderBottom: "2px solid black",
                  textAlign: "center",
                }}
              ></div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <label style={{ fontWeight: "bold" }}>Firma del Cliente</label>
              </div>
            </Col>
          </Row>

          <div className="flash" />
        </div>
      </div>
  );
});
