import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loader from "../../../../components/loader";
import axiosClient from "../../../../config/axios";
import notification from "../../../../components/Notification";
import { useCommon } from "../../../../context/CommonContext";

const ModalUpdateNoExpedient = ({ onClose, expediente }) => {
  const { id } = useParams();

  const { fetchOrder, setFetchOrder } = useCommon();

  const [value, setValue] = useState(expediente);
  const [loading, setLoading] = useState(false);

  const updateDeliveryDate = async () => {
    setLoading(true);
   // console.log({expediente})
    try {
      await axiosClient.put(`/orders/update-no-expedient/${id}`, {
        expediente: value,
      });
      setFetchOrder(!fetchOrder);
      notification("success", "No. Expediente actualizada correctamente");
    } catch (error) {
      notification("warning", "Ocurrió un error");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Loader isLoading={loading} />
      <Form.Label>Expediente no</Form.Label>
      <Form.Control
        type="text"
        name="expediente"
        placeholder="Número de expediente"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoComplete="off"
      />
      <div className="d-flex justify-content-end mt-3">
        <button className="btn btn-secondary me-2" onClick={() => onClose()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => updateDeliveryDate()}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ModalUpdateNoExpedient;
