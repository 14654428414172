import React from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { format } from "date-fns";
import axiosClient from "../../config/axios";
import { es } from "date-fns/locale";

import ServiceColumn from "./ServiceColumn";
import { formatName, formatNumberWithCommas } from "../../utils";
import CustomCheckbox from "../formik/CustomCheckbox";
import CustomInput from "../formik/CustomInput";

const RowOrderReceivable = ({ order }) => {
  const [loading, setLoading] = React.useState(false);
  const [firstTime, setFirstTime] = React.useState(false);
  const [originalState, setOriginalState] = React.useState({
    is_paid: order?.is_paid || false,
    paid_date: order?.paid_date
      ? new Date(order?.paid_date).toISOString().split("T")[0]
      : undefined,
  });

  const formik = useFormik({
    enableReinitialize: true,
    iniitalValues: {
      is_paid: false,
      paid_date: new Date(),
    },
    onSubmit: async (values) => {
      if (values.paid_date !== undefined) {
        setLoading(true);
        try {
          await axiosClient.post(
            `/reports/update-order-receivable/${order.id}`,
            {
              ...values,
            }
          );
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }

      setOriginalState({ ...values });
    },
  });

  React.useEffect(() => {
    formik.setValues({
      is_paid: order?.is_paid || false,
      paid_date: order?.paid_date
        ? new Date(order?.paid_date).toISOString().split("T")[0]
        : undefined,
    });
    setFirstTime(true);
  }, [order]);

  React.useEffect(() => {
    if (
      Boolean(
        originalState.is_paid !== formik?.values?.is_paid ||
          originalState.paid_date !== formik?.values?.paid_date
      ) &&
      firstTime
    ) {
      formik.handleSubmit();
    }
  }, [formik?.values?.is_paid, formik?.values?.paid_date]);

  return (
    <tr
      className="text-center"
      style={{
        pointerEvents: loading ? "none" : "auto",
        opacity: loading ? 0.5 : 1,
        cursor: loading ? 'not-allowed' : "default"
      }}
    >
      <td>
        <Row className="mb-2">
          <Col md="12">
            <Link to={`/orders/edit/${order.id}`}>
              {order.folio.toString().padStart(5, "0")}
            </Link>
          </Col>
        </Row>
      </td>
      <td>
        <ServiceColumn row={order} />
      </td>
      <td>
        {format(new Date(order.fecha_hora_reporte), "dd/MMM/yyyy", {
          locale: es,
        })}
      </td>
      <td>
        $
        {formatNumberWithCommas(order.total) ||
          Number(order.quick_order_price) ||
          "0.00"}
      </td>
      <td
        style={{
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {formik?.values?.is_paid !== undefined && (
            <CustomCheckbox formik={formik} field="is_paid" sm={1} />
          )}
          <CustomInput formik={formik} field="paid_date" type="date" sm={6} />
        </div>
      </td>
    </tr>
  );
};

export default RowOrderReceivable;
