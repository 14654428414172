import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import axiosClient from '../../../../config/axios.js';
import { FieldArray } from 'formik';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import OrderFieldsEvidenciasEvidencia from './orderFieldsEvidenciasEvidencia.js';

import { Link } from 'react-router-dom';
import { PanelBody } from '../../../../components/panel/panel.jsx';
import Lightbox from 'react-image-lightbox';
import ReactPlayer from 'react-player/lazy'
import '../lightbox.css'
import { v4 as uuidv4 } from 'uuid';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function OrderFieldsEvidencias({ id, values, setFieldValue, errors, setShowEvidences }) {
    const [files, setFiles] = useState([]);
    const [typeEvidences, setTypeEvidences] = useState([]);
    const [totalProgress, setTotalProgress] = useState(0);
    const [idsToDelete, setIdsToDelete] = useState([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [galleryNumber, setGalleryNumber] = useState(0);
    const [imageIndex, setImageIndex] = useState(0);
    const [allImages, setAllImages] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isVideo, setIsVideo] = useState(false);





    const [gallery, setGallery] = useState({
        all: true
    });

    const toggle = (value) => {
        let initialGalleryState = { all: false };

        // Iterar sobre possibleEvidences y agregar las llaves al objeto
        values?.evidences?.forEach((evidence, index) => {
            initialGalleryState[`group${index}`] = false;
        });

        initialGalleryState[value] = true;

        setGallery(initialGalleryState);
    };

    const fetchData = async () => {
        //console.logg("fetchData started");

        try {
            const response = await axiosClient.get(`/orders/activeEvidencesByOrder/${id}`, {
                params: {
                    only_active_evidences: true
                },
            });

            //console.logg("evidences registered", response.data);
            const formatted = response.data.map((evidence) => {
                return {
                    id: evidence.id,
                    evidence: evidence.evidence,
                    images: evidence.OrderEvidences
                };
            });

            let typeEvidencesUrl = values?.insurance_id?.value
                ? `/typeEvidences/insurance/${values.insurance_id.value}`
                : '/typeEvidences/all';
            //console.logg("formatted evidences", formatted);

            axiosClient.get(typeEvidencesUrl).then(response => {
                const possibleEvidences = response.data.data.map(item => {
                    const { id, evidence, InsuranceTypeEvidences } = item;
                    let requeridos = null;
                    if (!InsuranceTypeEvidences || InsuranceTypeEvidences.length === 0) {
                        requeridos = [{ images: [] }];
                    } else {
                        requeridos = InsuranceTypeEvidences.map(evidence => ({
                            grua_requerido: evidence.grua_requerido,
                            auxilio_vial_requerido: evidence.auxilio_vial_requerido,
                            montacarga_requerido: evidence.montacarga_requerido,
                            corresponsalia_requerido: evidence.corresponsalia_requerido,
                            taxi_requerido: evidence.taxi_requerido,
                            observaciones: evidence.observaciones,
                            images: []
                        }));
                    }

                    const requeridosFinal = requeridos.length === 1 ? requeridos[0] : requeridos;

                    return {
                        id,
                        evidence,
                        ...requeridosFinal
                    };
                });

                formatted.forEach(formattedEvidence => {
                    const match = possibleEvidences.find(possible => possible.id === formattedEvidence.id);
                    if (match) {
                        match.images = formattedEvidence.images;
                    } else {
                        possibleEvidences.push(formattedEvidence);
                    }
                });

                setFieldValue('evidences', possibleEvidences);

                let initialGalleryState = {};

                possibleEvidences.forEach((evidence, index) => {
                    initialGalleryState[`group${index}`] = false;
                });

                setGallery({
                    all: true,
                    ...initialGalleryState
                });

            }).catch(error => {
                console.error('Error fetching type Evidences:', error);
            });

           
           
           
           
           
           
           
           
           /*
           
            if (values?.insurance_id?.value) {
                //console.logg("se intenta obtener type evidences");
                axiosClient.get(`/typeEvidences/insurance/${values.insurance_id.value}`).then(response => {
                    const possibleEvidences = response.data.data.map(item => {
                        const { id, evidence, InsuranceTypeEvidences } = item;
                        let requeridos = null;
                        if (!InsuranceTypeEvidences || InsuranceTypeEvidences.length === 0) {
                            requeridos = [{ 
                                
                                images: [] 
                            }];
                        } else {
                            requeridos = InsuranceTypeEvidences.map(evidence => ({
                                grua_requerido: evidence.grua_requerido,
                                auxilio_vial_requerido: evidence.auxilio_vial_requerido,
                                montacarga_requerido: evidence.montacarga_requerido,
                                corresponsalia_requerido: evidence.corresponsalia_requerido,
                                taxi_requerido: evidence.taxi_requerido,
                                observaciones: evidence.observaciones,
                                images: []
                            }));
                        }

                        const requeridosFinal = requeridos.length === 1 ? requeridos[0] : requeridos;

                        return {
                            id,
                            evidence,
                            ...requeridosFinal
                        };
                    });

                    //console.logg("combined possibleEvidences", possibleEvidences);
                    //console.logg("combined formatted", formatted);
                    // Integrar formatted en possibleEvidences
                    formatted.forEach(formattedEvidence => {
                        const match = possibleEvidences.find(possible => possible.id === formattedEvidence.id);
                        if (match) {
                            match.images = formattedEvidence.images;
                        } else {
                            possibleEvidences.push(formattedEvidence);
                        }
                    });

                    //console.logg("combined ya combinados", possibleEvidences);
                    setFieldValue('evidences', possibleEvidences);

                    let initialGalleryState = {};

                    possibleEvidences.forEach((evidence, index) => {
                        initialGalleryState[`group${index}`] = false;
                    });

                    setGallery({
                        all: true,
                        ...initialGalleryState
                    });
                    //console.logg("initialGalleryState", initialGalleryState);
                }).catch(error => {
                    console.error('Error fetching type Evidences:', error);
                });
            }


*/







        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // Unir todas las imágenes de `evidences` en un solo array
        const images = values.evidences?.reduce((acc, gallery) => {
            return acc.concat(gallery.images);
        }, []);
        setAllImages(images);
    }, [values.evidences]);

    useEffect(() => {
        fetchData();
    }, [values.insurance_id, id]);

    useEffect(() => {
        if (values.evidences?.length > 0) {
           // console.log("cambio en evidences", values.evidences[0].images);
        }

    }, [values.evidences]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);


    useEffect(() => {
       // console.log("idsToDelete", idsToDelete);
    }, [idsToDelete]);

    const handleDeleteIdImage = (id) => {
        setIdsToDelete([...idsToDelete, id]);
    };

    const handleChange = async () => {
        try {
            const totalChunksGlobal = values.evidences.reduce((sum, evidence) =>
                sum + evidence.images.reduce((imageSum, image) =>
                    image.file ? imageSum + Math.ceil(image.file.size / (1024 * 1024)) : imageSum
                    , 0)
                , 0);

            let uploadedChunks = 0;

            const updateGlobalProgress = (chunkProgress) => {
                uploadedChunks += chunkProgress;
                setTotalProgress((uploadedChunks / totalChunksGlobal) * 100);
            };

            const uploadPromises = values.evidences.flatMap((evidence, indexEvidence) =>
                evidence.images
                    .filter(image => image.file)
                    .map((image, indexImage) =>
                        uploadFile(image, evidence.id, totalChunksGlobal, updateGlobalProgress)
                    )
            );

            await Promise.all(uploadPromises);
            await deleteFiles();
            await fetchData();

            Swal.fire({
                title: 'Operación exitosa',
                text: 'Se han guardado los cambios exitosamente',
                icon: 'success',
                confirmButtonText: 'Cerrar',
            });
        } catch (error) {
            Swal.fire({
                title: 'Ups!',
                text: 'Ha ocurrido un error. Favor de confirmar si se eliminaron o se agregaron todas las evidencias. Recargue la página e inténtelo nuevamente.',
                icon: 'error',
                confirmButtonText: 'Cerrar',
            });

            // Aquí podrías agregar lógica adicional para revertir cambios si es posible
            // Por ejemplo, podrías eliminar los archivos que ya se hayan subido correctamente
        }
    };

    const uploadFile = (image, typeEvidenceId, totalChunksGlobal, updateGlobalProgress) => {
        return new Promise(async (resolve, reject) => {
            try {
                const file = image?.file;
                const chunkSize = 1024 * 1024; // 1MB chunk size
                const totalChunks = Math.ceil(file.size / chunkSize);
                const fileUuid = uuidv4(); // Generate a UUID for this file

                for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                    const start = chunkIndex * chunkSize;
                    const end = Math.min(start + chunkSize, file.size);
                    const chunk = file.slice(start, end);

                    const parts = file?.name.split('.');

                    const formData = new FormData();
                    formData.append('chunk', chunk);
                    formData.append('chunkIndex', chunkIndex);
                    formData.append('fileExtension', parts[parts.length - 1]);
                    formData.append('totalChunks', totalChunks);
                    formData.append('fileUuid', fileUuid); // Append UUID
                    formData.append('typeEvidenceId', typeEvidenceId);
                    formData.append('video_thumbnail', image?.video_thumbnail || null);

                    await axiosClient.post(`/orders/evidences/${id}`, formData, {
                        onUploadProgress: (event) => {
                            const chunkProgress = (event.loaded / event.total);
                        }
                    }).catch(error => {
                        console.error('Error uploading chunk:', error.response ? error.response.data : error.message);
                        reject(error);
                    });

                    updateGlobalProgress(1);
                }
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };


    const deleteFiles = () => {
        return new Promise((resolve, reject) => {
            axiosClient.delete(`/orders/evidences/${id}`, {
                data: { ids: idsToDelete }
            }).then(response => {
                setIdsToDelete([]);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    };

    const handleModalClose = () => {
        setShowEvidences(false);
    };

    const hasFileDefined = values.evidences?.some(evidence =>
        evidence?.images.some(image => image.file !== undefined && image.file !== null)
    );


    const handleDownload = () => {
        const currentImage = allImages[imageIndex];
        const link = document.createElement('a');
        link.href = currentImage.filename_url;
        link.download = currentImage.filename_url.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const placeholderImage = "/assets/img/varo/archivo-no-visualizable.png"; // Asegúrate de tener una imagen alternativa válida

    return (

        <>

            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Evidencias</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" onClick={handleModalClose}></button>
                </div>
                <div className="modal-body">
                    <div id="options" className="mb-3">
                        <div className="d-flex flex-wrap text-nowrap mb-n1" id="filter">
                            <button onClick={() => toggle('all')} className={'btn btn-info btn-sm  me-3 mb-2 ' + (gallery.all ? 'active' : '')}>Mostrar TODO</button>

                            {values?.evidences?.map((evidence, i) => (
                                <button
                                    key={`buttonEvicenceOption${i}`}
                                    onClick={() => toggle(`group${i}`)}
                                    className={'btn btn-white btn-sm me-2 mb-2 ' + (gallery[`group${i}`] ? 'btn-gray' : '')}
                                >
                                    {evidence?.evidence?.toLowerCase().charAt(0).toUpperCase() + evidence?.evidence?.toLowerCase().slice(1)}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div id="gallery" className="gallery row gx-0">



                        <Row>

                            <FieldArray
                                name="evidences"
                                render={arrayHelpers => {
                                    const evidences = values.evidences;
                                    let globalImageIndex = 0;
                                    return (
                                        <>
                                            {evidences && evidences.length > 0
                                                ? evidences.map((evidence, evidenceIndex) => {
                                                    const evidenceImages = evidence.images || [];
                                                    globalImageIndex += evidenceImages.length;

                                                    return (
                                                        <OrderFieldsEvidenciasEvidencia
                                                            key={`OrderFieldsEvidenciasEvidencia_${evidenceIndex}`}
                                                            evidence={evidence}
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            gallery={gallery}
                                                            galleryNumber={evidenceIndex}
                                                            handleDeleteIdImage={handleDeleteIdImage}
                                                            setLightboxOpen={setLightboxOpen}
                                                            setGalleryNumber={setGalleryNumber}
                                                            setImageIndex={setImageIndex}
                                                            globalImageIndexStart={globalImageIndex - evidenceImages.length} // Pasamos el índice inicial
                                                        />
                                                    );
                                                })
                                                : null
                                            }
                                        </>
                                    );
                                }}
                            />



                        </Row>
                    </div>
                </div>
                <div className="modal-footer">
                    <PanelBody>
                        {totalProgress > 0 && (
                            <Row className={`mb-4 ${totalProgress === 100 ? 'fade-out' : ''}`}>
                                <Col md='12'>
                                    <div className="progress rounded-pill mb-2">
                                        <div className="progress-bar bg-info progress-bar-striped progress-bar-animated rounded-pill fs-10px fw-bold" style={{ width: `${totalProgress}%` }}>{totalProgress}%</div>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col md='12' className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-bs-dismiss="modal"
                                    onClick={handleModalClose}
                                >
                                    Cerrar {(idsToDelete.length === 0 && !hasFileDefined) ? '' : 'sin guardar cambios'}
                                </button>
                                <button onClick={handleChange} disabled={(idsToDelete.length === 0 && !hasFileDefined)} className="ms-2 btn btn-primary">Guardar cambios</button>
                            </Col>
                        </Row>
                    </PanelBody>


                </div>
            </div>

            {lightboxOpen && (
                <>
                    <Lightbox
                        mainSrc={hasError ? placeholderImage : allImages[imageIndex].filename_url}
                        nextSrc={allImages[(imageIndex + 1) % allImages.length].filename_url}
                        prevSrc={allImages[(imageIndex + allImages.length - 1) % allImages.length].filename_url}
                        onCloseRequest={() => { setLightboxOpen(false); setHasError(false); setIsVideo(false); }}
                        onMovePrevRequest={() => { setHasError(false); setIsVideo(false); setImageIndex((imageIndex + allImages.length - 1) % allImages.length) }}
                        onMoveNextRequest={() => { setHasError(false); setIsVideo(false); setImageIndex((imageIndex + 1) % allImages.length) }}
                        imageLoadErrorMessage='El archivo no puede visualizarse. Favor de descargarlo'
                        onImageLoadError={(e) => {
                            if (allImages[imageIndex].filename_url === e) {
                               // console.log("error loading image", e); setHasError(true)
                                setIsVideo(true);
                            }

                        }}
                        toolbarButtons={[
                            <button key="download" onClick={handleDownload} style={{ background: 'none', border: 'none', color: 'white' }}>
                                Descargar
                            </button>
                        ]}
                    />

                    {(isVideo && 0 === 1) && (

                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={allImages[imageIndex].filename_url}
                                width='100%'
                                height='100%'
                                playing
                                controls
                            />
                        </div>

                    )}
                </>


            )}
        </>







    );
}

export default OrderFieldsEvidencias;