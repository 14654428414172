import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [fullName, setFullName] = useState(localStorage.getItem('fullName') || '');
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [roles, setRoles] = useState(localStorage.getItem('roles') || [])

  useEffect(() => {
    localStorage.setItem('token', token);
    localStorage.setItem('fullName', fullName);
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);
    localStorage.setItem('roles', roles);
  }, [token, fullName, userId]);

  const login = (newToken, newFullName, newUserId, username, roles) => { 
    const rolesSave = JSON.stringify(roles)
    setToken(newToken);
    setFullName(newFullName);
    setUserId(newUserId);
    setUsername(username);
    setRoles(rolesSave);
  };

  const logout = () => {
    setToken(null);
    setFullName('');
    setUsername('');
    setUserId(null);
    setRoles([]);
    localStorage.removeItem('token');
    localStorage.removeItem('fullName');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('roles');
  };

  const isAdmin = () => {
    let admin = false;

    if (!Array.isArray(roles)) {
      const rolesArray = JSON.parse(roles ?? "[]");
      if (rolesArray.length > 0) {
        admin = rolesArray.includes("ADMINISTRADOR");
      }
    }
    return admin;
  }

  const isAccountant = () => {
    let accountant = false;

    if (!Array.isArray(roles)) {
      const rolesArray = JSON.parse(roles);
      if (rolesArray.length > 0) {
        accountant = rolesArray.includes("CONTADOR");
      }
    }
    return accountant;
  }
  
  const isOffice = () => {
    let office = false;

    if (!Array.isArray(roles)) {
      const rolesArray = JSON.parse(roles);
      if (rolesArray.length > 0) {
        office = rolesArray.includes("OFICINA");
      }
    }
    return office;
  }

  return (
    <AuthContext.Provider value={{ token, fullName, userId, login, logout, username, roles, isAdmin, isAccountant, isOffice }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};