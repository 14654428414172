import React, { useState, useEffect } from 'react';
import { GoogleMap, Polygon, LoadScript, Marker } from '@react-google-maps/api';

function LocalRateMapCoordinates({ values, setFieldValue }) {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const [map, setMap] = useState(null);
  //const [values.coordinates, setVertices] = useState([]);
  const [initialCenter, setInitialCenter] = useState({
    lat: 17.0731842,
    lng: -96.7265889
  });



  const handleMapClick = (e) => {
    const newVertex = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    //setVertices([...values.coordinates, newVertex]);
    setFieldValue('coordinates', [...values.coordinates, newVertex])

    map.panTo({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
    /*setInitialCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });*/
  };

  const handleDoubleClick = (e) => {
   // console.log("e", e);
    //e.preventDefault();
  };

  const handleVertexDrag = (index, newPosition) => {
    const updatedVertices = [...values.coordinates];
    updatedVertices[index] = { lat: newPosition.latLng.lat(), lng: newPosition.latLng.lng() };
    setFieldValue('coordinates', updatedVertices);
    //setVertices(updatedVertices);
  };

  const handleDeleteVertex = (index) => {
    const updatedVertices = [...values.coordinates];
    updatedVertices.splice(index, 1);
    setFieldValue('coordinates', updatedVertices);
    //setVertices(updatedVertices);
  };

  useEffect(() => {
   // console.log(values.coordinates);
  }, [values.coordinates]);


  return (
    <>
      <div className="note alert-primary">
      
        <div className="note-icon"><i className="fa-solid fa-earth-americas"></i></div>
        <div className="note-content">
          <h4><b>Ajuste de límite geográfico para tarifas locales</b></h4>
          <p> Dé clic sobre el mapa para agregar un nuevo marcador secuencial al polígono, dé clic sobre el marcador para eliminarlo o arrastre los marcadores para ajustar la localización.  </p>
        </div>
      </div>

      <LoadScript googleMapsApiKey={googleMapsApiKey}>
        <GoogleMap
          onLoad={map => setMap(map)}
          onClick={handleMapClick}
          onDblClick={handleDoubleClick}
          mapContainerStyle={{ width: '100%', height: '800px' }}
          zoom={10}
          center={initialCenter}
          options={{
            gestureHandling: 'cooperative'
          }}
        >
          <Polygon
            paths={values.coordinates}
            draggable={false}
            onDragEnd={(e, index) => handleVertexDrag(index, e)}
            options={{
              fillColor: '#000',
              fillOpacity: 0.4,
              strokeColor: '#000',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
          {/* Render logic for delete buttons on values.coordinates */}
          {values.coordinates.map((vertex, index) => (
            <Marker
              key={index}
              position={{ lat: vertex.lat, lng: vertex.lng }}
              draggable={true}
              onDragEnd={(e) => handleVertexDrag(index, e)}
              onClick={() => handleDeleteVertex(index)}
              label={(index + 1).toString()}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </>

  );
};

export default LocalRateMapCoordinates;