export function formatNumberWithCommas(number) {
  if (number) {
      const roundedNumber = parseFloat(number).toFixed(2);
      return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

export function formatName(user) {
  const fullName = `${user.name} ${user.first_surname} ${user.second_surname}`;
  return fullName
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function toCamelCase(str) {
  return str
      .toLowerCase() // Convertir todo a minúsculas
      .split(' ') // Dividir por espacios
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar la primera letra
      .join(' '); // Unir las palabras de nuevo
}