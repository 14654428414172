import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import { FieldArray, Field, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';

function InsurersFields({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) {

    const [typeEvidences, setTypeEvidences] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const fetchTypeEvidences = async () => {
        axiosClient.get(`/typeEvidences/all`, {
            params: {
            }
        }).then(response => {
            const formattedOptions = response.data.data.map(typeEvidence => ({
                value: typeEvidence.id,
                label: typeEvidence.evidence
            }));
            setTypeEvidences(formattedOptions)
        }).catch(error => {
            console.error('Error fetching typeEvidence:', error);
        });
    };

    const handleCreateTypeEvidence = (inputValue, index) => {
        Swal.fire({
            title: '¿Está seguro que desea agregar el nuevo tipo de Evidencia?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar Tipo de Evidencia',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {

                axiosClient.post('/typeEvidence/save', {
                    evidence: inputValue
                }).then(response => {
                    const newOption = {
                        value: response.data.id,
                        label: response.data.evidence
                    };

                   // console.log("responseeeeee", response.data);

                    setTypeEvidences((prev) => [...prev, newOption]);
                    setFieldValue(`typeEvidences.${index}.evidence`, newOption);

                }).catch(err => {

                    Swal.fire({
                        title: 'Ups!',
                        text: 'Ha ocurrido un error con el registro del Tipo de evidencia.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: false,
                    });
                });
            }
        })
    };

    useEffect(() => {
        fetchTypeEvidences();
    }, []);

    useEffect(() => {
       // console.log("render");
        // Construye el objeto de opciones seleccionadas para inicializar selectedOptions
        if (values.typeEvidences) {
            const initialSelectedOptions = values.typeEvidences.map((typeEvidence, index) => {
                return typeEvidence.evidence ? { value: typeEvidence.evidence.value, label: typeEvidence.evidence.label } : null;
            });
            setSelectedOptions(initialSelectedOptions);
        }
    }, [values.typeEvidences]);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Nombre de la Aseguradora</Form.Label>
                    <Form.Control
                        type="text"
                        name="insurance_name"
                        placeholder="Nombre de la Aseguradora"
                        value={values.insurance_name}
                        onChange={handleChange}
                        isInvalid={!!errors.insurance_name}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.insurance_name}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <FieldArray
                name="insuranceContacts"
                render={arrayHelpers => {

                    return (
                        <>

                            <Row className='mb-4'>
                                <div className="row">
                                    <div className="col-md-12">

                                        <Link to='#'
                                            className="btn btn-success btn-rounded px-4 rounded-pill float-end"
                                            onClick={() =>
                                                arrayHelpers.push({
                                                    name: "",
                                                    position: "",
                                                    phone: "",
                                                    phone_extension: ""
                                                })
                                            }
                                        >
                                            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Agregar Contacto
                                        </Link>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-invoice">
                                        <thead>
                                            <tr>
                                                <th width="40%">Nombre completo</th>
                                                <th width="30%">Puesto</th>
                                                <th width="15%">Teléfono</th>
                                                <th width="10%">Extensión</th>
                                                <th width="5%">Eliminar</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {values.insuranceContacts && values.insuranceContacts.length > 0
                                                ? values.insuranceContacts.map((insuranceContact, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <span className="text-dark">
                                                                <Field
                                                                    placeholder="Nombre completo"
                                                                    className="form-control"
                                                                    name={`insuranceContacts.${index}.name`}
                                                                    autoComplete="off"
                                                                />
                                                            </span>
                                                            <span className='invalid-feedback' style={{ display: 'block' }}>
                                                                <ErrorMessage name={`insuranceContacts.${index}.name`} />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="text-dark">
                                                                <Field
                                                                    placeholder="Puesto"
                                                                    className="form-control"
                                                                    name={`insuranceContacts.${index}.position`}
                                                                    autoComplete="off"
                                                                />
                                                            </span>
                                                            <span className='invalid-feedback' style={{ display: 'block' }}>
                                                                <ErrorMessage name={`insuranceContacts.${index}.position`} />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="text-dark">
                                                                <InputMask
                                                                    name={`insuranceContacts.${index}.phone`}
                                                                    mask="(999) 999-9999"
                                                                    className="form-control"
                                                                    placeholder="(999) 999-9999"
                                                                    value={values.insuranceContacts[index].phone}
                                                                    onChange={(e) => {
                                                                       // console.log(e.target.value);
                                                                        setFieldValue(`insuranceContacts.${index}.phone`, e.target.value);
                                                                    }}
                                                                />
                                                            </span>
                                                            <span className='invalid-feedback' style={{ display: 'block' }}>
                                                                <ErrorMessage name={`insuranceContacts.${index}.phone`} />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="text-dark">
                                                                <Field
                                                                    placeholder="Extensión"
                                                                    className="form-control"
                                                                    name={`insuranceContacts.${index}.phone_extension`}
                                                                    autoComplete="off"
                                                                />
                                                            </span>
                                                            <span className='invalid-feedback' style={{ display: 'block' }}>
                                                                <ErrorMessage name={`insuranceContacts.${index}.phone_extension`} />
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(index); // Elimina la fila del FieldArray
                                                                }}
                                                            >
                                                                <span className='fa fa-trash' />
                                                            </button>
                                                        </td>

                                                    </tr>
                                                ))
                                                : null
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                        </>
                    );
                }}
            />

            <FieldArray
                name="typeEvidences"
                render={arrayHelpers => {

                    return (
                        <Row className='mb-4'>

                            <Row className='mt-2 mb-4'>
                                <Col md="4"><Form.Label>Evidencia</Form.Label></Col>
                                <Col md="2"><Form.Label>Requerido</Form.Label></Col>
                                <Col md="4"><Form.Label>Etiqueta de observaciones</Form.Label></Col>
                                <Col md="2"><Form.Label>Eliminar</Form.Label></Col>
                            </Row>

                            {values.typeEvidences && values.typeEvidences.length > 0
                                ? values.typeEvidences.map((typeEvidence, index) => (
                                    <div key={`arrayTypeEvidences_${values.typeEvidences[index].id}_${index}`}>
                                        <Row key={index}>
                                            <Col md="4">
                                                <span className="text-dark">
                                                    <Form.Group as={Col} md="12" >

                                                        <Select
                                                            isClearable
                                                            onChange={(newValue) => {
                                                                setFieldValue(`typeEvidences.${index}.evidence`, newValue);
                                                                setSelectedOptions(prevOptions => ({
                                                                    ...prevOptions,
                                                                    [index]: newValue
                                                                }));
                                                            }}
                                                            onCreateOption={(inputValue) => handleCreateTypeEvidence(inputValue, index)}
                                                            options={typeEvidences}
                                                            value={values.typeEvidences[index].evidence}
                                                            name={`typeEvidences.${index}.evidence`}
                                                            placeholder='Selecciona o registra un Tipo de Evidencia'
                                                            className={errors?.typeEvidences && errors.typeEvidences[index] && errors.typeEvidences[index].evidence ? 'is-invalid' : ''}
                                                            formatCreateLabel={(inputValue) => `Crear nuevo Tipo de Evidencia: ${inputValue}`}
                                                            noOptionsMessage={() => `No se encontraron opciones`}
                                                            style={{ position: 'relative', zIndex: '9999 !important' }}
                                                            isOptionDisabled={(option) => {
                                                                // Verifica si la opción actual está seleccionada en algún otro CreatableSelect
                                                                return Object.values(selectedOptions).some(
                                                                    selectedOption => selectedOption && selectedOption.value === option.value
                                                                );
                                                            }}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.typeEvidences && errors.typeEvidences[index] && errors.typeEvidences[index].evidence ? errors.typeEvidences[index].evidence : null}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>



                                                </span>
                                            </Col>
                                            <Col md="2">
                                                <Row className='mb-1'>
                                                    <span className="text-dark">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={values.typeEvidences[index].grua_requerido}
                                                            name={`typeEvidences.${index}.grua_requerido`}
                                                            onChange={(e) => { setFieldValue(`typeEvidences.${index}.grua_requerido`, e.target.checked ? true : false); }}
                                                        />
                                                        <label className="ms-1 form-check-label" htmlFor={`typeEvidences.${index}.grua_requerido`}>
                                                            Grúa
                                                        </label>
                                                    </span>
                                                </Row>
                                                <Row className='mb-1'>
                                                    <span className="text-dark">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={values.typeEvidences[index].auxilio_vial_requerido}
                                                            name={`typeEvidences.${index}.auxilio_vial_requerido`}
                                                            onChange={(e) => { setFieldValue(`typeEvidences.${index}.auxilio_vial_requerido`, e.target.checked ? true : false); }}
                                                        />
                                                        <label className="ms-1 form-check-label" htmlFor={`typeEvidences.${index}.auxilio_vial_requerido`}>
                                                            Auxilio vial
                                                        </label>
                                                    </span>
                                                </Row>
                                                <Row className='mb-1'>
                                                    <span className="text-dark">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={values.typeEvidences[index].montacarga_requerido}
                                                            name={`typeEvidences.${index}.montacarga_requerido`}
                                                            onChange={(e) => { setFieldValue(`typeEvidences.${index}.montacarga_requerido`, e.target.checked ? true : false); }}
                                                        />
                                                        <label className="ms-1 form-check-label" htmlFor={`typeEvidences.${index}.montacarga_requerido`}>
                                                            Montacarga
                                                        </label>
                                                    </span>
                                                </Row>
                                                <Row className='mb-1'>
                                                    <span className="text-dark">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={values.typeEvidences[index].corresponsalia_requerido}
                                                            name={`typeEvidences.${index}.corresponsalia_requerido`}
                                                            onChange={(e) => { setFieldValue(`typeEvidences.${index}.corresponsalia_requerido`, e.target.checked ? true : false); }}
                                                        />
                                                        <label className="ms-1 form-check-label" htmlFor={`typeEvidences.${index}.corresponsalia_requerido`}>
                                                            Corresponsalía
                                                        </label>
                                                    </span>
                                                </Row>
                                                <Row className='mb-1'>
                                                    <span className="text-dark">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={values.typeEvidences[index].taxi_requerido}
                                                            name={`typeEvidences.${index}.taxi_requerido`}
                                                            onChange={(e) => { setFieldValue(`typeEvidences.${index}.taxi_requerido`, e.target.checked ? true : false); }}
                                                        />
                                                        <label className="ms-1 form-check-label" htmlFor={`typeEvidences.${index}.taxi_requerido`}>
                                                            Taxi
                                                        </label>
                                                    </span>
                                                </Row>


                                            </Col>
                                            <Col md="4">
                                                <span className="text-dark">
                                                    <textarea
                                                        name={`typeEvidences.${index}.observaciones`}
                                                        className={`form-control ${errors.datos_contacto_seguimiento_observaciones ? 'is-invalid' : ''}`}
                                                        value={values.typeEvidences[index].observaciones}
                                                        onChange={(e) => {
                                                            setFieldValue(`typeEvidences.${index}.observaciones`, e.target.value);
                                                        }}

                                                    ></textarea>
                                                </span>
                                                <span className='invalid-feedback' style={{ display: 'block' }}>
                                                    <ErrorMessage name={`typeEvidences.${index}.observaciones`} />
                                                </span>
                                            </Col>
                                            <Col md="2">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        arrayHelpers.remove(index); // Elimina la fila del FieldArray
                                                    }}
                                                >
                                                    <span className='fa fa-trash' />
                                                </button>
                                            </Col>

                                        </Row>
                                        <Row className='mt-2 mb-4'>
                                            <hr className="bg-gray-500 mt-0 mb-0" />
                                        </Row>

                                    </div>

                                ))
                                : null
                            }

                            <div className="row">
                                <div className="col-md-12">

                                    <Link to='#'
                                        className="btn btn-success btn-rounded px-4 rounded-pill float-end"
                                        onClick={() =>
                                            arrayHelpers.push({
                                                id: null,
                                                evidence: '',
                                                grua_requerido: false,
                                                auxilio_vial_requerido: false,
                                                montacarga_requerido: false,
                                                corresponsalia_requerido: false,
                                                taxi_requerido: false,
                                                observaciones: ''
                                            })
                                        }
                                    >
                                        <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Agregar Evidencia
                                    </Link>
                                </div>
                            </div>
                        </Row>

                    );
                }}
            />


        </>
    );
};

export default InsurersFields;