import React, { useState, useEffect } from "react";
import axiosClient from "../../../../config/axios.js";
import { FieldArray, useFormikContext } from "formik";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Button } from "bootstrap/dist/js/bootstrap.bundle.js";
import DateTime from "react-datetime";
import Select from "react-select";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import CurrencyInput from "react-currency-input-field";
import { Tooltip } from "react-tooltip";
import { useCommon } from "../../../../context/CommonContext.js";
import { useAuth } from "../../../../context/AuthContext.js";

function TableInvoices({
  paymentTypeCode,
  valuesKey,
  nomenclaturaInicial,
  disabled,
}) {
  const { id } = useParams();
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext();
  const { isAccountant } = useAuth();

  const [bankAccounts, setBankAccounts] = useState([]);
  const { fetchOrder } = useCommon();

  const fetchData = async () => {
    try {
      const response = await axiosClient.get(
        `/invoices/activeInvoicesByOrder/${id}`,
        {
          params: {
            only_active_invoices: true,
            payment_type_code: paymentTypeCode,
          },
        }
      );

      const formatted = response.data.rows.map((invoice) => {
       // console.log("item invoice response", invoice);
        return {
          id: invoice.id,
          invoice_date: moment(invoice.invoice_date).toDate(),
          invoice_amount: invoice.invoice_amount,
          invoice_number: invoice.invoice_number,
          creator: (
            invoice.creator_invoice.name +
            " " +
            invoice.creator_invoice.first_surname +
            " " +
            (invoice.creator_invoice.second_surname
              ? invoice.creator_invoice.second_surname
              : "")
          ).trim(),
          created_at: invoice.createdAt,
          payment_type_id: invoice.PaymentType?.id,
          invoice_retention: invoice?.invoice_retention,
        };
      });
     if (valuesKey && formatted.length > 0) {
      setFieldTouched(valuesKey, true);
      setFieldValue(valuesKey, formatted);
    }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, fetchOrder, values?.current_order_status_id]);

  return (
    <>
      <FieldArray
        name={`${valuesKey}`}
        render={(arrayHelpers) => {
          const invoices = values[valuesKey];
          return (
            <>
              <Row className="mb-3">
                <div className="col-md-12">
                  <div className="">
                    <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                      <thead className="sticky-thead table-light text-center">
                        <tr>
                          <th></th>
                          <th>FACTURA REGISTRADA POR</th>
                          <th>NÚMERO DE FACTURA</th>
                          <th>FECHA DE FACTURA</th>
                          <th>Subtotal</th>
                          <th>IVA 16%</th>
                          <th>¿RETENCIÓN?</th>
                          <th>RETENIDO</th>
                          <th>TOTAL</th>
                        </tr>
                      </thead>

                      <tbody key="montos_grua_foraneo">
                        {values[valuesKey] && values[valuesKey].length > 0
                          ? values[valuesKey].map((invoice, index) => (
                              <tr
                                key={`tr_invoices_${index}`}
                                className={`table  table-sm bg-white-100 table-bordered ${
                                  index % 2 === 1 ? "" : ""
                                }`}
                              >
                                <td className="text-center align-middle">
                                  {!disabled && (
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-icon btn-circle btn-sm"
                                      onClick={() => {
                                        arrayHelpers.remove(index); // Elimina la fila del FieldArray
                                      }}
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {invoice.created_at && (
                                    <>
                                      {format(
                                        new Date(invoice.created_at),
                                        "dd/MMM/yyyy",
                                        { locale: es }
                                      )}{" "}
                                      {format(
                                        new Date(invoice.created_at),
                                        "hh:mm a",
                                        { locale: es }
                                      )}
                                      <br></br>
                                      {invoice.creator}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Row>
                                    <Col md="12">
                                      <Form.Group as={Col} md="12" className="">
                                        <Form.Control
                                          type="text"
                                          name={`${valuesKey}.${index}.invoice_number`}
                                          className={`${
                                            errors[valuesKey] &&
                                            errors[valuesKey][index] &&
                                            errors[valuesKey] &&
                                            errors[valuesKey][index]
                                              .invoice_number
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Número de factura"
                                          value={
                                            values[valuesKey][index]
                                              .invoice_number
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `${valuesKey}.${index}.invoice_number`,
                                              e?.target?.value || ""
                                            );
                                          }}
                                          autoComplete="off"
                                          disabled={disabled}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors[valuesKey] &&
                                          errors[valuesKey][index] &&
                                          errors[valuesKey] &&
                                          errors[valuesKey][index]
                                            .invoice_number
                                            ? errors[valuesKey][index]
                                                .invoice_number
                                            : ""}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  <Col>
                                    <Row>
                                      <Form.Group as={Col} md="12">
                                        <DateTime
                                          name={`${valuesKey}.${index}.invoice_date`}
                                          inputProps={{
                                            placeholder: "Fecha factura",
                                            disabled: disabled,
                                            readOnly: true,
                                          }}
                                          closeOnSelect={true}
                                          locale="es"
                                          value={
                                            values[valuesKey][index]
                                              ?.invoice_date
                                              ? format(
                                                  new Date(
                                                    values[valuesKey][
                                                      index
                                                    ].invoice_date
                                                  ),
                                                  "EEEE dd/MMMM/yyyy",
                                                  { locale: es }
                                                )
                                              : ""
                                          }
                                          className={`${
                                            errors[valuesKey] &&
                                            errors[valuesKey][index] &&
                                            errors[valuesKey] &&
                                            errors[valuesKey][index]
                                              .invoice_date
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          dateFormat="dddd DD/MMMM/YYYY"
                                          timeFormat={false}
                                          initialViewMode="days"
                                          onOpen={(e) => {
                                            setFieldTouched(
                                              `${valuesKey}.${index}.invoice_date`,
                                              true
                                            );
                                          }}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `${valuesKey}.${index}.invoice_date`,
                                              e ? e : ""
                                            );
                                          }}
                                          isInvalid={
                                            touched.invoice_date &&
                                            !!errors.invoice_date
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors[valuesKey] &&
                                          errors[valuesKey][index] &&
                                          errors[valuesKey] &&
                                          errors[valuesKey][index].invoice_date
                                            ? errors[valuesKey][index]
                                                .invoice_date
                                            : ""}
                                        </Form.Control.Feedback>
                                        {errors.invoice_date ? (
                                          <div className="invalid-feedback">
                                            {errors.invoice_date}
                                          </div>
                                        ) : null}
                                      </Form.Group>
                                    </Row>
                                  </Col>
                                </td>
                                <td>
                                  <Form.Group as={Col} md="12" className="">
                                    <InputGroup
                                      className={` ${
                                        errors[valuesKey] &&
                                        errors[valuesKey][index] &&
                                        errors[valuesKey][index]?.invoice_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    >
                                      <InputGroup.Text>
                                        <i className="fa-solid fa-file-invoice"></i>
                                      </InputGroup.Text>
                                      <CurrencyInput
                                        autoComplete="off"
                                        className={`form-control ${
                                          errors[valuesKey] &&
                                          errors[valuesKey][index] &&
                                          errors[valuesKey][index]
                                            ?.invoice_amount
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        prefix=""
                                        allowNegativeValue={false}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        decimalSeparator="."
                                        groupSeparator=","
                                        name={`${valuesKey}.${index}.invoice_amount`}
                                        value={
                                          values[valuesKey][index]
                                            .invoice_amount
                                        }
                                        onValueChange={(value) => {
                                          setFieldTouched(
                                            `${valuesKey}.${index}.invoice_amount`,
                                            true
                                          );
                                          setFieldValue(
                                            `${valuesKey}.${index}.invoice_amount`,
                                            value
                                          );
                                        }}
                                        disabled={disabled}
                                      />
                                    </InputGroup>
                                    {errors[valuesKey] &&
                                    errors[valuesKey][index] &&
                                    errors[valuesKey][index].invoice_amount ? (
                                      <div
                                        className="invalid-feedback"
                                        style={{ color: "#ea4335" }}
                                      >
                                        {
                                          errors[valuesKey][index]
                                            .invoice_amount
                                        }
                                      </div>
                                    ) : null}
                                  </Form.Group>
                                </td>
                                <td>
                                  <center>
                                    <Row>
                                      <Col md="12">
                                        <Form.Group
                                          as={Col}
                                          md="12"
                                          className=""
                                        >
                                          <Form.Control
                                            type="text"
                                            name={`${valuesKey}.${index}.invoice_iva`}
                                            value={
                                              values[valuesKey][index]
                                                .invoice_amount * 0.16
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `${valuesKey}.${index}.invoice_total_retention`,
                                                e?.target?.value || ""
                                              );
                                            }}
                                            autoComplete="off"
                                            disabled={true}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </center>
                                </td>
                                <td>
                                  <center>
                                    <Row>
                                      <Col md="12">
                                        <Form.Group
                                          as={Col}
                                          md="12"
                                          className=""
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            name={`${valuesKey}.${index}.invoice_retention`}
                                            value={
                                              values[valuesKey][index]
                                                .invoice_retention
                                            }
                                            checked={
                                              values[valuesKey][index]
                                                .invoice_retention
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `${valuesKey}.${index}.invoice_retention`,
                                                e?.target?.checked || ""
                                              );
                                            }}
                                            autoComplete="off"
                                            disabled={disabled}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </center>
                                </td>
                                <td>
                                  {values[valuesKey][index]
                                    .invoice_retention && (
                                    <center>
                                      <Row>
                                        <Col md="12">
                                          <Form.Group
                                            as={Col}
                                            md="12"
                                            className=""
                                          >
                                            <Form.Control
                                              type="text"
                                              name={`${valuesKey}.${index}.invoice_total_retention`}
                                              value={
                                                Number(
                                                  values[valuesKey][index]
                                                    .invoice_amount * 0.16
                                                ) * 0.25
                                              }
                                              // value={150}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `${valuesKey}.${index}.invoice_total_retention`,
                                                  e?.target?.value || ""
                                                );
                                              }}
                                              autoComplete="off"
                                              disabled={true}
                                            />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </center>
                                  )}
                                </td>
                                <td>
                                  {/* {values[valuesKey][index].invoice_retention &&   */}
                                  <center>
                                    <Row>
                                      <Col md="12">
                                        <Form.Group
                                          as={Col}
                                          md="12"
                                          className=""
                                        >
                                          <Form.Control
                                            type="text"
                                            name={`${valuesKey}.${index}.invoice_total_retention`}
                                            // value={Number(values[valuesKey][index].invoice_amount * .16) * 0.25}
                                            value={
                                              !values[valuesKey][index]
                                                .invoice_retention
                                                ? (
                                                    Number(
                                                      values[valuesKey][index]
                                                        .invoice_amount
                                                    ) +
                                                    Number(
                                                      values[valuesKey][index]
                                                        .invoice_amount
                                                    ) *
                                                      0.16
                                                  ).toFixed(2)
                                                : (
                                                    Number(
                                                      values[valuesKey][index]
                                                        .invoice_amount
                                                    ) +
                                                    Number(
                                                      values[valuesKey][index]
                                                        .invoice_amount
                                                    ) *
                                                      0.16 -
                                                    Number(
                                                      values[valuesKey][index]
                                                        .invoice_amount
                                                    ) *
                                                      0.16 *
                                                      0.25
                                                  ).toFixed(2)
                                            }
                                            autoComplete="off"
                                            disabled={true}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </center>
                                </td>
                              </tr>
                            ))
                          : null}
                        {!disabled && (
                          <tr>
                            <td colSpan={9}>
                              <Row className="mb-3">
                                <div className="col-md-12">
                                  <div className="d-flex align-items-center float-end">
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-success btn-icon btn-circle btn-sm "
                                        onClick={() =>
                                          arrayHelpers.push({
                                            id: "",
                                            invoice_number:
                                              nomenclaturaInicial || "",
                                            invoice_date: "",
                                            created_by_user_id: "",
                                            invoice_amount: "",
                                            payment_type_code: paymentTypeCode,
                                          })
                                        }
                                      >
                                        <i className="fa-solid fa-plus"></i>
                                      </button>
                                    </div>

                                    <div className="ms-1">Agregar factura</div>
                                  </div>
                                </div>
                              </Row>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
            </>
          );
        }}
      />
    </>
  );
}

export default TableInvoices;
