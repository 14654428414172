import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Form from "react-bootstrap/Form";
import DateTime from "react-datetime";
import Loader from "../../../../components/loader";
import axiosClient from "../../../../config/axios";
import notification from "../../../../components/Notification";
import { useCommon } from "../../../../context/CommonContext";

const ModalUpdateDeliveryDate = ({ onClose, contactDate }) => {
  const { id } = useParams();

  const { fetchOrder, setFetchOrder } = useCommon();

  const deliveryDate = useRef(null);

  const [value, setValue] = useState(contactDate);
  const [loading, setLoading] = useState(false);

  const updateDeliveryDate = async () => {
    setLoading(true);
    try {
      await axiosClient.put(`/orders/update-delivery-date/${id}`, {
        fecha_hora_entrega: moment(value).format(),
      });
      setFetchOrder(!fetchOrder);
      notification("success", "Fecha y hora actualizada correctamente");
    } catch (error) {
      notification("warning", "Ocurrió un error");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Loader isLoading={loading} />
      <Form.Label>Fecha y hora de entrega</Form.Label>
      <DateTime
        name="fecha_hora_entrega"
        ref={deliveryDate}
        inputProps={{
          placeholder: "Fecha y hora de entrega",
        }}
        closeOnSelect={false}
        locale="es"
        value={value}
        dateFormat="dddd DD/MMMM/YYYY"
        timeFormat="hh:mm A"
        initialViewMode="days"
        onOpen={(e) => {
          deliveryDate.current.navigate("days");
        }}
        onChange={(e) => {
          setValue(e);
        }}
      />
      <div className="d-flex justify-content-end mt-3">
        <button className="btn btn-secondary me-2" onClick={() => onClose()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => updateDeliveryDate()}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ModalUpdateDeliveryDate;
