import React from 'react';

import App from './../app.jsx';

import Orders from './../pages/varo/orders/orders.js';
import NewOrder from './../pages/varo/orders/newOrder.js';
import EditOrder from './../pages/varo/orders/editOrder.js';
import SettingsUsers from './../pages/varo/settings/users/users.js';
import NewUser from './../pages/varo/settings/users/newUser.js';
import EditUser from './../pages/varo/settings/users/editUser.js';
import ChangePassword from './../pages/varo/settings/users/changePassword.js';
import ChangeCurrentUserPassword from '../pages/varo/auth/changeCurrentUserPassword.js';

import Roles from './../pages/varo/settings/roles/roles.js';
import NewRole from './../pages/varo/settings/roles/newRole.js';
import EditRole from './../pages/varo/settings/roles/editRole.js';

import Insurers from './../pages/varo/catalogs/insurers/insurers.js';
import NewInsurance from './../pages/varo/catalogs/insurers/newInsurance.js';
import EditInsurance from './../pages/varo/catalogs/insurers/editInsurance.js';

import TypeEvidences from './../pages/varo/catalogs/insurers/typeEvidences/typeEvidences.js';

import Locations from './../pages/varo/catalogs/locations/locations.js';
import NewLocation from './../pages/varo/catalogs/locations/newLocation.js';
import EditLocation from './../pages/varo/catalogs/locations/editLocation.js';

import BrandsModels from './../pages/varo/catalogs/brandsModels/brandsModels.js';
import NewBrand from './../pages/varo/catalogs/brandsModels/newBrand.js';
import EditBrand from './../pages/varo/catalogs/brandsModels/editBrand.js';
import NewVehicleModel from './../pages/varo/catalogs/brandsModels/newVehicleModel.js';
import EditVehicleModel from './../pages/varo/catalogs/brandsModels/editVehicleModel.js';

import ForeignRates from './../pages/varo/catalogs/rates/foreignRates.js';
import LocalRates from './../pages/varo/catalogs/rates/localRates.js';

import Agreements from './../pages/varo/catalogs/agreements/agreements.js';
import NewAgreement from './../pages/varo/catalogs/agreements/newAgreement.js';
import EditAgreement from './../pages/varo/catalogs/agreements/editAgreement.js';

import CraneTypes from './../pages/varo/catalogs/craneTypes/craneTypes.js';
import NewCraneType from './../pages/varo/catalogs/craneTypes/newCraneType.js';
import EditCraneType from './../pages/varo/catalogs/craneTypes/editCraneType.js';


import ShieldingTypes from './../pages/varo/catalogs/shieldingTypes/shieldingTypes.js';
import NewShieldingType from './../pages/varo/catalogs/shieldingTypes/newShieldingType.js';
import EditShieldingType from './../pages/varo/catalogs/shieldingTypes/editShieldingType.js';

import Cranes from './../pages/varo/catalogs/cranes/cranes.js';
import NewCrane from './../pages/varo/catalogs/cranes/newCrane.js';
import EditCrane from './../pages/varo/catalogs/cranes/editCrane.js';

import LiftTrucks from './../pages/varo/catalogs/liftTrucks/liftTrucks.js';
import NewLiftTruck from './../pages/varo/catalogs/liftTrucks/newLiftTruck.js';
import EditLiftTruck from './../pages/varo/catalogs/liftTrucks/editLiftTruck.js';

import AssistanceVehicles from './../pages/varo/catalogs/assistanceVehicles/assistanceVehicles.js';
import NewAssistanceVehicle from './../pages/varo/catalogs/assistanceVehicles/newAssistanceVehicle.js';
import EditAssistanceVehicle from './../pages/varo/catalogs/assistanceVehicles/editAssistanceVehicle.js';

import Colors from './../pages/varo/catalogs/colors/colors.js';
import NewColor from './../pages/varo/catalogs/colors/newColor.js';
import EditColor from './../pages/varo/catalogs/colors/editColor.js';






import FormPlugins from './../pages/form/form-plugins.js';

//Varo
import Login from '../pages/varo/auth/login.js';
import GetCoords from '../pages/varo/catalogs/rates/getCoords.js';
import OrderHistory from '../pages/varo/orders/orderHistory/orderHistory.js';

import SqlView from '../pages/varo/system/sqlView.js';
import NewTypeEvidence from '../pages/varo/catalogs/insurers/typeEvidences/newTypeEvidences.js';
import EditTypeEvidence from '../pages/varo/catalogs/insurers/typeEvidences/editTypeEvidences.js';
import Banks from '../pages/varo/catalogs/banksAndBankAccounts/banks/banks.js';
import NewBank from '../pages/varo/catalogs/banksAndBankAccounts/banks/newBank.js';
import EditBank from '../pages/varo/catalogs/banksAndBankAccounts/banks/editBank.js';
import BankAccounts from '../pages/varo/catalogs/banksAndBankAccounts/bankAccounts/bankAccounts.js';
import NewBankAccount from '../pages/varo/catalogs/banksAndBankAccounts/bankAccounts/newBankAccounts.js';
import EditBankAccount from '../pages/varo/catalogs/banksAndBankAccounts/bankAccounts/editBankAccounts.js';
import DuplicateOrder from '../pages/varo/orders/duplicateOrder.js';
import OperatorSalary from '../pages/varo/reports/OperatorSalary.js';
import OrderInvoice from '../pages/varo/reports/OrderInvoice.js';
import OrderInsurance from '../pages/varo/reports/OrderInsurance.js';
import TimelineOrder from '../pages/varo/orders/timelineOrder.js';
import OrdersReceivable from '../pages/varo/reports/OrdersReceivable.js';

const AppRoute = [
	{
		path: '*',
		element: <App />,
		children: [
			{
				path: 'login',
				element: <Login />
			},
			{
				path: 'orders',
				element: <Orders />
			},
			{
				path: 'settings/users',
				element: <SettingsUsers />
			},
			{
				path: 'settings/users/changePassword/:id',
				element: <ChangePassword />
			},
			{
				path: 'settings/users/newUser',
				element: <NewUser />
			},
			{
				path: 'settings/users/edit/:id',
				element: <EditUser />
			},
			{
				path: 'settings/roles',
				element: <Roles />
			},
			{
				path: 'settings/roles/newRole',
				element: <NewRole />
			},
			{
				path: 'settings/roles/edit/:id',
				element: <EditRole />
			},
			{
				path: 'catalogs/insurers',
				element: <Insurers />
			},
			{
				path: 'catalogs/insurers/newInsurance',
				element: <NewInsurance />
			},
			{
				path: 'catalogs/insurers/edit/:id',
				element: <EditInsurance />
			},
			{
				path: 'catalogs/typeEvidences',
				element: <TypeEvidences />
			},
			{
				path: 'catalogs/typeEvidences/newTypeEvidence',
				element: <NewTypeEvidence />

			},
			{
				path: 'catalogs/typeEvidences/edit/:id',
				element: <EditTypeEvidence />
			},
			{
				path: 'catalogs/banks',
				element: <Banks />
			},
			{
				path: 'catalogs/bankAccounts',
				element: <BankAccounts />
			},
			{
				path: 'catalogs/bankAccounts',
				element: <EditTypeEvidence />
			},
			{
				path: 'catalogs/locations',
				element: <Locations />
			},
			{
				path: 'catalogs/locations/newLocation',
				element: <NewLocation />
			},
			{
				path: 'catalogs/locations/edit/:id',
				element: <EditLocation />
			},
			{
				path: 'catalogs/brandsModels',
				element: <BrandsModels />
			},
			{
				path: 'catalogs/brands/newBrand',
				element: <NewBrand />
			},
			{
				path: 'catalogs/brands/edit/:id',
				element: <EditBrand />
			},
			{
				path: 'catalogs/models/newVehicleModel',
				element: <NewVehicleModel />
			},
			{
				path: 'catalogs/models/edit/:id',
				element: <EditVehicleModel />
			},
			{
				path: 'orders/newOrder',
				element: <NewOrder />
			},
			{
				path: 'orders/edit/:id',
				element: <EditOrder />
			},
			{
				path: 'orders/duplicate/:id',
				element: <DuplicateOrder />
			},
      {
        path: 'orders/timeline/:id',
        element:<TimelineOrder/>
      },
			{
				path: 'orders/history/:id',
				element: <OrderHistory />
			},
			{
				path: 'catalogs/foreignRates',
				element: <ForeignRates />
			},
			{
				path: 'catalogs/localRates',
				element: <LocalRates />
			},
			{
				path: 'catalogs/agreements',
				element: <Agreements />
			},
			{
				path: 'catalogs/agreements/newAgreement',
				element: <NewAgreement />
			},
			{
				path: 'catalogs/agreements/edit/:id',
				element: <EditAgreement />
			},
			{
				path: 'catalogs/crane-types',
				element: <CraneTypes />
			},
			{
				path: 'catalogs/crane-types/newCraneType',
				element: <NewCraneType />
			},
			{
				path: 'catalogs/crane-types/edit/:id',
				element: <EditCraneType />
			},
			{
				path: 'catalogs/shieldingTypes',
				element: <ShieldingTypes />
			},
			{
				path: 'catalogs/shieldingTypes/new',
				element: <NewShieldingType />
			},
			{
				path: 'catalogs/shieldingTypes/edit/:id',
				element: <EditShieldingType />
			},
			{
				path: 'catalogs/cranes',
				element: <Cranes />
			},
			{
				path: 'catalogs/cranes/newCrane',
				element: <NewCrane />
			},
			{
				path: 'catalogs/cranes/edit/:id',
				element: <EditCrane />
			},
			{
				path: 'catalogs/assistanceVehicles',
				element: <AssistanceVehicles />
			},
			{
				path: 'catalogs/assistanceVehicles/newAssistanceVehicle',
				element: <NewAssistanceVehicle />
			},
			{
				path: 'catalogs/assistanceVehicles/edit/:id',
				element: <EditAssistanceVehicle />
			},
			{
				path: 'catalogs/liftTrucks',
				element: <LiftTrucks />
			},
			{
				path: 'catalogs/liftTrucks/newLiftTruck',
				element: <NewLiftTruck />
			},
			{
				path: 'catalogs/liftTrucks/edit/:id',
				element: <EditLiftTruck />
			},
			{
				path: 'catalogs/colors',
				element: <Colors />
			},
			{
				path: 'catalogs/colors/newColor',
				element: <NewColor />
			},
			{
				path: 'catalogs/colors/edit/:id',
				element: <EditColor />
			},
			{
				path: 'catalogs/banks/newBank',
				element: <NewBank />
			},
			{
				path: 'catalogs/banks/edit/:id',
				element: <EditBank />
			},
			{
				path: 'catalogs/bankAccounts/newBankAccount',
				element: <NewBankAccount />
			},
			{
				path: 'catalogs/bankAccounts/edit/:id',
				element: <EditBankAccount />
			},
			{
				path: 'catalogs/rates/getCoords',
				element: <GetCoords />
			},
			{
				path: 'sqlView',
				element: <SqlView />
			},
			{
				path: 'auth/changePassword',
				element: <ChangeCurrentUserPassword />
			},
			{
				path: 'reports/operators-salary',
				element: <OperatorSalary />
			},
      {
				path: 'reports/order-invoice',
				element: <OrderInvoice />
			},
      {
				path: 'reports/order-insurance',
				element: <OrderInsurance />
			},
      {
				path: 'reports/orders-receivable',
				element: <OrdersReceivable />
			},
		]
	}
];


export default AppRoute;



/**
 
import React from "react";

import App from "./../app.jsx";

import Orders from "./../pages/varo/orders/orders.js";
import NewOrder from "./../pages/varo/orders/newOrder.js";
import EditOrder from "./../pages/varo/orders/editOrder.js";
import SettingsUsers from "./../pages/varo/settings/users/users.js";
import NewUser from "./../pages/varo/settings/users/newUser.js";
import EditUser from "./../pages/varo/settings/users/editUser.js";
import ChangePassword from "./../pages/varo/settings/users/changePassword.js";
import ChangeCurrentUserPassword from "../pages/varo/auth/changeCurrentUserPassword.js";

import Roles from "./../pages/varo/settings/roles/roles.js";
import NewRole from "./../pages/varo/settings/roles/newRole.js";
import EditRole from "./../pages/varo/settings/roles/editRole.js";

import Insurers from "./../pages/varo/catalogs/insurers/insurers.js";
import NewInsurance from "./../pages/varo/catalogs/insurers/newInsurance.js";
import EditInsurance from "./../pages/varo/catalogs/insurers/editInsurance.js";

import TypeEvidences from "./../pages/varo/catalogs/insurers/typeEvidences/typeEvidences.js";

import Locations from "./../pages/varo/catalogs/locations/locations.js";
import NewLocation from "./../pages/varo/catalogs/locations/newLocation.js";
import EditLocation from "./../pages/varo/catalogs/locations/editLocation.js";

import BrandsModels from "./../pages/varo/catalogs/brandsModels/brandsModels.js";
import NewBrand from "./../pages/varo/catalogs/brandsModels/newBrand.js";
import EditBrand from "./../pages/varo/catalogs/brandsModels/editBrand.js";
import NewVehicleModel from "./../pages/varo/catalogs/brandsModels/newVehicleModel.js";
import EditVehicleModel from "./../pages/varo/catalogs/brandsModels/editVehicleModel.js";

import ForeignRates from "./../pages/varo/catalogs/rates/foreignRates.js";
import LocalRates from "./../pages/varo/catalogs/rates/localRates.js";

import Agreements from "./../pages/varo/catalogs/agreements/agreements.js";
import NewAgreement from "./../pages/varo/catalogs/agreements/newAgreement.js";
import EditAgreement from "./../pages/varo/catalogs/agreements/editAgreement.js";

import CraneTypes from "./../pages/varo/catalogs/craneTypes/craneTypes.js";
import NewCraneType from "./../pages/varo/catalogs/craneTypes/newCraneType.js";
import EditCraneType from "./../pages/varo/catalogs/craneTypes/editCraneType.js";

import ShieldingTypes from "./../pages/varo/catalogs/shieldingTypes/shieldingTypes.js";
import NewShieldingType from "./../pages/varo/catalogs/shieldingTypes/newShieldingType.js";
import EditShieldingType from "./../pages/varo/catalogs/shieldingTypes/editShieldingType.js";

import Cranes from "./../pages/varo/catalogs/cranes/cranes.js";
import NewCrane from "./../pages/varo/catalogs/cranes/newCrane.js";
import EditCrane from "./../pages/varo/catalogs/cranes/editCrane.js";

import LiftTrucks from "./../pages/varo/catalogs/liftTrucks/liftTrucks.js";
import NewLiftTruck from "./../pages/varo/catalogs/liftTrucks/newLiftTruck.js";
import EditLiftTruck from "./../pages/varo/catalogs/liftTrucks/editLiftTruck.js";

import AssistanceVehicles from "./../pages/varo/catalogs/assistanceVehicles/assistanceVehicles.js";
import NewAssistanceVehicle from "./../pages/varo/catalogs/assistanceVehicles/newAssistanceVehicle.js";
import EditAssistanceVehicle from "./../pages/varo/catalogs/assistanceVehicles/editAssistanceVehicle.js";

import Colors from "./../pages/varo/catalogs/colors/colors.js";
import NewColor from "./../pages/varo/catalogs/colors/newColor.js";
import EditColor from "./../pages/varo/catalogs/colors/editColor.js";

import FormPlugins from "./../pages/form/form-plugins.js";

//Varo
import Login from "../pages/varo/auth/login.js";
import GetCoords from "../pages/varo/catalogs/rates/getCoords.js";
import OrderHistory from "../pages/varo/orders/orderHistory/orderHistory.js";

import SqlView from "../pages/varo/system/sqlView.js";
import NewTypeEvidence from "../pages/varo/catalogs/insurers/typeEvidences/newTypeEvidences.js";
import EditTypeEvidence from "../pages/varo/catalogs/insurers/typeEvidences/editTypeEvidences.js";
import Banks from "../pages/varo/catalogs/banksAndBankAccounts/banks/banks.js";
import NewBank from "../pages/varo/catalogs/banksAndBankAccounts/banks/newBank.js";
import EditBank from "../pages/varo/catalogs/banksAndBankAccounts/banks/editBank.js";
import BankAccounts from "../pages/varo/catalogs/banksAndBankAccounts/bankAccounts/bankAccounts.js";
import NewBankAccount from "../pages/varo/catalogs/banksAndBankAccounts/bankAccounts/newBankAccounts.js";
import EditBankAccount from "../pages/varo/catalogs/banksAndBankAccounts/bankAccounts/editBankAccounts.js";
import DuplicateOrder from "../pages/varo/orders/duplicateOrder.js";
import OperatorSalary from "../pages/varo/reports/OperatorSalary.js";
import { Navigate } from "react-router-dom";

import { useEffect, useState } from "react";
import axiosClient from './axios.js';

let routesConfig = [
  {
    path: "*",
    element: <App />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "settings/users",
        element: <SettingsUsers />,
      },
      {
        path: "settings/users/changePassword/:id",
        element: <ChangePassword />,
      },
      {
        path: "settings/users/newUser",
        element: <NewUser />,
      },
      {
        path: "settings/users/edit/:id",
        element: <EditUser />,
      },
      {
        path: "settings/roles",
        element: <Roles />,
      },
      {
        path: "settings/roles/newRole",
        element: <NewRole />,
      },
      {
        path: "settings/roles/edit/:id",
        element: <EditRole />,
      },
      {
        path: "catalogs/insurers",
        element: <Insurers />,
      },
      {
        path: "catalogs/insurers/newInsurance",
        element: <NewInsurance />,
      },
      {
        path: "catalogs/insurers/edit/:id",
        element: <EditInsurance />,
      },
      {
        path: "catalogs/typeEvidences",
        element: <TypeEvidences />,
      },
      {
        path: "catalogs/typeEvidences/newTypeEvidence",
        element: <NewTypeEvidence />,
      },
      {
        path: "catalogs/typeEvidences/edit/:id",
        element: <EditTypeEvidence />,
      },
      {
        path: "catalogs/banks",
        element: <Banks />,
      },
      {
        path: "catalogs/bankAccounts",
        element: <BankAccounts />,
      },
      {
        path: "catalogs/bankAccounts",
        element: <EditTypeEvidence />,
      },
      {
        path: "catalogs/locations",
        element: <Locations />,
      },
      {
        path: "catalogs/locations/newLocation",
        element: <NewLocation />,
      },
      {
        path: "catalogs/locations/edit/:id",
        element: <EditLocation />,
      },
      {
        path: "catalogs/brandsModels",
        element: <BrandsModels />,
      },
      {
        path: "catalogs/brands/newBrand",
        element: <NewBrand />,
      },
      {
        path: "catalogs/brands/edit/:id",
        element: <EditBrand />,
      },
      {
        path: "catalogs/models/newVehicleModel",
        element: <NewVehicleModel />,
      },
      {
        path: "catalogs/models/edit/:id",
        element: <EditVehicleModel />,
      },
      {
        path: "orders/newOrder",
        element: <NewOrder />,
      },
      {
        path: "orders/edit/:id",
        element: <EditOrder />,
      },
      {
        path: "orders/duplicate/:id",
        element: <DuplicateOrder />,
      },
      {
        path: "orders/history/:id",
        element: <OrderHistory />,
      },
      {
        path: "catalogs/foreignRates",
        element: <ForeignRates />,
      },
      {
        path: "catalogs/localRates",
        element: <LocalRates />,
      },
      {
        path: "catalogs/agreements",
        element: <Agreements />,
      },
      {
        path: "catalogs/agreements/newAgreement",
        element: <NewAgreement />,
      },
      {
        path: "catalogs/agreements/edit/:id",
        element: <EditAgreement />,
      },
      {
        path: "catalogs/crane-types",
        element: <CraneTypes />,
      },
      {
        path: "catalogs/crane-types/newCraneType",
        element: <NewCraneType />,
      },
      {
        path: "catalogs/crane-types/edit/:id",
        element: <EditCraneType />,
      },
      {
        path: "catalogs/shieldingTypes",
        element: <ShieldingTypes />,
      },
      {
        path: "catalogs/shieldingTypes/new",
        element: <NewShieldingType />,
      },
      {
        path: "catalogs/shieldingTypes/edit/:id",
        element: <EditShieldingType />,
      },
      {
        path: "catalogs/cranes",
        element: <Cranes />,
      },
      {
        path: "catalogs/cranes/newCrane",
        element: <NewCrane />,
      },
      {
        path: "catalogs/cranes/edit/:id",
        element: <EditCrane />,
      },
      {
        path: "catalogs/assistanceVehicles",
        element: <AssistanceVehicles />,
      },
      {
        path: "catalogs/assistanceVehicles/newAssistanceVehicle",
        element: <NewAssistanceVehicle />,
      },
      {
        path: "catalogs/assistanceVehicles/edit/:id",
        element: <EditAssistanceVehicle />,
      },
      {
        path: "catalogs/liftTrucks",
        element: <LiftTrucks />,
      },
      {
        path: "catalogs/liftTrucks/newLiftTruck",
        element: <NewLiftTruck />,
      },
      {
        path: "catalogs/liftTrucks/edit/:id",
        element: <EditLiftTruck />,
      },
      {
        path: "catalogs/colors",
        element: <Colors />,
      },
      {
        path: "catalogs/colors/newColor",
        element: <NewColor />,
      },
      {
        path: "catalogs/colors/edit/:id",
        element: <EditColor />,
      },
      {
        path: "catalogs/banks/newBank",
        element: <NewBank />,
      },
      {
        path: "catalogs/banks/edit/:id",
        element: <EditBank />,
      },
      {
        path: "catalogs/bankAccounts/newBankAccount",
        element: <NewBankAccount />,
      },
      {
        path: "catalogs/bankAccounts/edit/:id",
        element: <EditBankAccount />,
      },
      {
        path: "catalogs/rates/getCoords",
        element: <GetCoords />,
      },
      {
        path: "sqlView",
        element: <SqlView />,
      },
      {
        path: "auth/changePassword",
        element: <ChangeCurrentUserPassword />,
      },
      {
        path: "reports/operators-salary",
        element: <OperatorSalary />,
      },
    ],
  },
];

const AppRoute = () => {
  const [permisos, setPermisos] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState([]);

  // Función para filtrar rutas según los permisos
  const filterMenuByPermissions = (menu, permissions) => {
    return menu.map((item) => {
      if (item.children) {
        item.children = filterMenuByPermissions(item.children, permissions);
      }

      // Verificar si el usuario tiene permiso para acceder a esta ruta
      const hasPermission = !item.permission || permissions.includes(item.permission);

      return hasPermission ? item : null;
    }).filter(Boolean);
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const userId = localStorage.getItem("userId");

        const response = await axiosClient.get(`/roles/${userId}`);

        const responsePermissions = await axiosClient.get(`/roles/findPermissions/${response?.data?.id}`);

        if (responsePermissions?.data) {
          const permisosExtraidos = responsePermissions.data.flatMap((item) =>
            item.permissions.map((permiso) => permiso.description)
          );

          setPermisos(permisosExtraidos);
        }
      } catch (error) {
        console.error("Error obteniendo los permisos:", error);
      }
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    if (permisos.length > 0) {
      setFilteredMenu(filterMenuByPermissions(routesConfig, permisos));
    }
  }, [permisos]);

  return filteredMenu;
};

export default AppRoute;
 */