import React from "react";

import { getCurrentFortnight } from "../../models/ReportOperatorSalary";
import RowOrderReceivable from "./RowOrderReceivable";

const TableOrdersReceivable = ({ orders }) => {
  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead className="sticky-thead table-light text-center">
          <tr>
            <th className="align-middle">Orden {orders.length}</th>
            <th className="align-middle">Tipo del servicio</th>
            <th className="align-middle">Fecha</th>
            <th className="align-middle">Total</th>
            <th className="align-middle">¿Ya fue cobrada?</th>
          </tr>
        </thead>

        <tbody>
          {orders
            // .sort(
            //   (a, b) =>
            //     new Date(a.fecha_hora_reporte) - new Date(b.fecha_hora_reporte)
            // )
            // .filter(
            //   (order) =>
            //     new Date(order.fecha_hora_reporte) >=
            //       getCurrentFortnight().start &&
            //     new Date(order.fecha_hora_reporte) <= getCurrentFortnight().end
            // )
            .map((order, index) => (
              <RowOrderReceivable order={order} key={index} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableOrdersReceivable;
