export const craneA = 1.75;
export const craneB = 1.75;
export const craneC = 2.7;
export const craneD = 3.5;

export const ReportOperatorSalary = () => {
  const { start, end } = getCurrentFortnight();

  return {
    start: start.toISOString().split("T")[0],
    end: end.toISOString().split("T")[0],
    operator: "Todos",
    status: "Todas",
  };
};

export const getCurrentFortnight = () => {
  const fechaActual = new Date();
  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth();
  const anio = fechaActual.getFullYear();

  let fechaInicio, fechaFin;

  if (dia <= 15) {
    // Primera quincena
    fechaInicio = new Date(anio, mes, 1);
    fechaFin = new Date(anio, mes, 15);
  } else {
    // Segunda quincena
    fechaInicio = new Date(anio, mes, 16);
    fechaFin = new Date(anio, mes + 1, 0);
  }

  const opcionesFormato = { year: "numeric", month: "long", day: "numeric" };

  return {
    // fechaInicio: fechaInicio.toLocaleDateString('es-ES', opcionesFormato),
    // fechaFin: fechaFin.toLocaleDateString('es-ES', opcionesFormato)
    start: fechaInicio,
    end: fechaFin,
  };
};

export const getCurrentFortnightName = () => {
  const fechaActual = new Date();
  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth();
  const anio = fechaActual.getFullYear();

  const spanishMonth = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  let name = "";

  if (dia <= 15) {
    name = "Primera quincena";
  } else {
    name = "Segunda quincena";
  }

  return `${name} de ${spanishMonth[mes]} ${anio}`;
};

export const getOrderType = (order) => {
  if (order.montacarga) {
    return "No aplica";
  }

  if (order.subtotal <= 700) return "Local";

  return "Foraneo";
};

export const getComissionPerKM = (order) => {
  if (order.montacarga) {
    const horas = order.montacargas_horas || 1;

    return 180 + 80 * (horas - 1);
  }

  if (order.subtotal <= 700) return 15;

  return 1.75 * order.totalDistance;
};

export const getReason = (order) => {
  if (order.montacarga) {
    const horas = order.montacargas_horas || 1;
    return `Fueron ${horas} hora${horas > 1 ? "s" : ""} de montacarga`;
  }

  if (order.subtotal <= 700) return "Fue un servicio local";

  return "Fue un servicio foraneo";
};

export const getComission = (order) => {
  if (order.montacarga) {
    const horas = order.montacargas_horas;

    return 180 + 80 * (horas - 1);
  }

  if (order.subtotal <= 700) return 15;

  return 1.75 * order.totalDistance;
};

export const craneComission = (crane) => {
  const letter = crane?.trim().split(" ").pop();

  switch (letter) {
    case "A":
      return craneA;
    case "B":
      return craneB;
    case "C":
      return craneC;
    case "D":
      return craneD;
    default:
      return 0;
  }
};

export const getComissionPerMontacarga = (order) => {
  const totalHours = order?.montacargas_horas || 1;
  const subtotal = order?.subtotal || 0;
  const percentage = 0.05;

  if (totalHours === 1) {
    return 150 + subtotal * percentage;
  }

  return 150 + subtotal * percentage + 80 * (totalHours - 1);
};

export const getComissionPerCrane = (order) => {
  if (order.montacarga) {
    return getComissionPerMontacarga(order);
  }
  const crane = order?.Crane?.CraneTypes?.at(0)?.crane_type_name;
  const comission = craneComission(crane) ?? 0;
  const totalKM = Number(order.totalDistance).toFixed(2) ?? 0;

  return comission * totalKM;
};
