import React, { useState, useEffect, useRef } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import OrderStatusAsignadaSinEnviar from './orderStatusAsignadaSinEnviar.js';
import OrderStatusEnviada from './orderStatusEnviada.js';
import OrderStatusContacto from './orderStatusContacto.js';
import OrderStatusLlegada from './orderStatusLlegada.js';
import OrderStatusPagado from './orderStatusPagado.js';
import OrderStatusEntrega from './orderStatusEntrega.js';
import OrderStatusEvaluado from './orderStatusEvaluado.js';
import OrderStatusCierre from './orderStatusCierre.js';
import OrderStatusInvoice from './orderStatusInvoice.js';


function OrderStatus({ fetchCurrentOrderStatusByOrder, reloadOrderDataFromDB, tipo_pago_id, fecha_hora_envio, fecha_hora_contacto, fecha_hora_llegada, operadores, touched, setFieldValue, setFieldTouched, values, currentOrderStatus, setCurrentOrderStatus, updateOrder }) {
    const { id } = useParams();
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [modalInstance, setModalInstance] = useState(null);
    const [showModalContent, setShowModalContent] = useState(false);
    const modalRef = useRef();
    const handleStepClick = (index, order_status_code, order_status) => {
        //console.log("order_status_code", order_status_code)
        if (currentOrderStatus) {
            //console.log("order_status_code", order_status_code)
            if (values.orderStatuses[order_status_code].is_next_status) {
                //console.log("order_status_code", order_status_code)
                const modal = modalRef.current;
                if (modal) {
                    //console.log("order_status_code", order_status_code)
                    const modalInstance = new bootstrap.Modal(modal);
                    setModalInstance(modalInstance);
                    modalInstance.show();

                    setShowModalContent(true);

                    if (order_status_code === 'PAGADO_CONTADO' || order_status_code === 'PAGADO_CREDITO') {
                        //console.log("order_status_code 1", order_status_code)
                        setFieldValue("payments", []);
                    }
                }


            }
        }
    };

    useEffect(() => {
        if (modalInstance) {
            // Agregar evento al cerrar la ventana modal
            modalInstance._element.addEventListener('hidden.bs.modal', handleModalClose);
        }

        // Limpiar el evento al desmontar el componente
        return () => {
            if (modalInstance) {
                modalInstance._element.removeEventListener('hidden.bs.modal', handleModalClose);
            }
        };
    }, [modalInstance]);

    const handleModalClose = () => {
        //console.log('La ventana modal se ha cerrado.');
        // Aquí puedes realizar cualquier acción que desees después de cerrar la ventana modal
        setShowModalContent(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const response = await fetchCurrentOrderStatusByOrder();
                setCurrentOrderStatus(response);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        //console.log("orderStatuses antes modificación", orderStatuses);
        if (currentOrderStatus && orderStatuses) {
            let encontrado = true;
            let indexNextStatus = null;
            orderStatuses.forEach((orderStatus, index) => {
                orderStatus.has_passed_status = encontrado;
                orderStatus.is_next_status = false;
                if (currentOrderStatus.order_status_code === orderStatus.order_status_code) {
                    encontrado = false;
                    indexNextStatus = index + 1;
                }
            });

            if (indexNextStatus < orderStatuses.length && orderStatuses[indexNextStatus]) {
                orderStatuses[indexNextStatus].is_next_status = true;
            }

            const transformedObject = orderStatuses.reduce((acc, item) => {
                acc[item.order_status_code] = {
                    has_passed_status: item.has_passed_status,
                    is_next_status: item.is_next_status
                };
                return acc;
            }, {});
            setFieldTouched("orderStatuses", true);
            setFieldValue("orderStatuses", transformedObject);

        }
    }, [currentOrderStatus, orderStatuses]);

    useEffect(() => {
        if (tipo_pago_id) {
            //console.log("tipo_pago_id tipo_pago_id", tipo_pago_id);
            axiosClient.get('/orderStatuses/all', {
                params: {
                    payment_type_code: tipo_pago_id.code
                },
            }).then(response => {
                const formattedOptions = response.data.data.map(orderStatus => ({
                    value: orderStatus.id,
                    label: orderStatus.order_status,
                    order_status: orderStatus.order_status,
                    order_status_code: orderStatus.order_status_code,
                    expected_lifecycle_status: orderStatus.expected_lifecycle_status,
                    order_status_hint: orderStatus.order_status_hint,
                    order: orderStatus.order
                }));
                setOrderStatuses(formattedOptions);
            }).catch(error => {
                console.error('Error fetching clientTypes:', error);
            });
        } else {
            setOrderStatuses([]);
        }

    }, [tipo_pago_id])


    return (
        <>
            <div className="modal modal-message fade" ref={modalRef} id="modalMessage">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {id && currentOrderStatus && (
                            <>
                                {currentOrderStatus.order_status_code === 'SIN_ASIGNAR' && (
                                    <OrderStatusAsignadaSinEnviar
                                        operadores={operadores}
                                        fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                        setCurrentOrderStatus={setCurrentOrderStatus}
                                        modalInstance={modalInstance}
                                        reloadOrderDataFromDB={reloadOrderDataFromDB}
                                    />
                                )}

                                {currentOrderStatus.order_status_code === 'ASIGNADA_SIN_ENVIAR' && (
                                    <OrderStatusEnviada
                                        fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                        setCurrentOrderStatus={setCurrentOrderStatus}
                                        modalInstance={modalInstance}
                                        reloadOrderDataFromDB={reloadOrderDataFromDB}
                                    />
                                )}

                                {currentOrderStatus.order_status_code === 'ENVIADA' && (
                                    <>
                                        {fecha_hora_envio !== null && (
                                            <OrderStatusContacto
                                                fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                                setCurrentOrderStatus={setCurrentOrderStatus}
                                                modalInstance={modalInstance}
                                                reloadOrderDataFromDB={reloadOrderDataFromDB}
                                                fecha_hora_envio={fecha_hora_envio}
                                            />
                                        )}
                                    </>
                                )}

                                {currentOrderStatus.order_status_code === 'CONTACTO' && (
                                    <>
                                        {fecha_hora_contacto && (
                                            <OrderStatusLlegada
                                                fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                                setCurrentOrderStatus={setCurrentOrderStatus}
                                                modalInstance={modalInstance}
                                                reloadOrderDataFromDB={reloadOrderDataFromDB}
                                                fecha_hora_contacto={fecha_hora_contacto}
                                            />
                                        )}
                                    </>
                                )}

                                {(((tipo_pago_id.code === 'CONTADO' || tipo_pago_id.code === 'CONTADO/CREDITO') && currentOrderStatus.order_status_code === 'LLEGADA')) && (
                                    <OrderStatusPagado
                                        paymentTypeCode='CONTADO'
                                        fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                        setCurrentOrderStatus={setCurrentOrderStatus}
                                        modalInstance={modalInstance}
                                        reloadOrderDataFromDB={reloadOrderDataFromDB}
                                        order_status_code='PAGADO_CONTADO'
                                        orderValues={values}
                                    />
                                )}

                                {((currentOrderStatus.order_status_code === 'PAGADO_CONTADO') || (tipo_pago_id.code === 'CREDITO' && currentOrderStatus.order_status_code === 'LLEGADA')) && (
                                    <>
                                        {fecha_hora_llegada && (
                                            <OrderStatusEntrega
                                                totalPendingToPay={values?.totalPendingToPay}
                                                fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                                setCurrentOrderStatus={setCurrentOrderStatus}
                                                modalInstance={modalInstance}
                                                reloadOrderDataFromDB={reloadOrderDataFromDB}
                                                fecha_hora_llegada={fecha_hora_llegada}
                                            />
                                        )}
                                    </>
                                )}

                                {currentOrderStatus.order_status_code === 'ENTREGA' && (
                                    <OrderStatusEvaluado
                                        fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                        setCurrentOrderStatus={setCurrentOrderStatus}
                                        modalInstance={modalInstance}
                                        reloadOrderDataFromDB={reloadOrderDataFromDB}
                                    />
                                )}

                                {showModalContent && (((tipo_pago_id.code === 'CREDITO' || tipo_pago_id.code === 'CONTADO/CREDITO') && currentOrderStatus.order_status_code === 'EVALUADO')) && (
                                    <OrderStatusPagado
                                        paymentTypeCode='CREDITO'
                                        fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                        setCurrentOrderStatus={setCurrentOrderStatus}
                                        modalInstance={modalInstance}
                                        reloadOrderDataFromDB={reloadOrderDataFromDB}
                                        order_status_code='PAGADO_CREDITO'
                                        orderValues={values}
                                    />
                                )}

                                {(
                                    (tipo_pago_id.code !== 'CONTADO' && currentOrderStatus.order_status_code === 'PAGADO_CREDITO')
                                    || (tipo_pago_id.code === 'CONTADO' && currentOrderStatus.order_status_code === 'EVALUADO')
                                ) && (
                                        <OrderStatusCierre
                                            updateOrder={updateOrder}
                                            fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                            setCurrentOrderStatus={setCurrentOrderStatus}
                                            modalInstance={modalInstance}
                                            reloadOrderDataFromDB={reloadOrderDataFromDB}
                                            order_status_code='CIERRE'
                                        />
                                    )}
                                { values.need_invoice &&  currentOrderStatus.order_status_code === 'CIERRE' && 
                                    (<OrderStatusInvoice
                                        fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                        setCurrentOrderStatus={setCurrentOrderStatus}
                                        modalInstance={modalInstance}
                                        reloadOrderDataFromDB={reloadOrderDataFromDB}
                                        order_status_code='FACTURA'
                                        fecha_hora_envio={fecha_hora_envio}
                                        need_invoice={values.need_invoice}
                                    />)
                                }
                                    
                            </>
                        )}
                    </div>
                </div>
            </div>

            { }
            {id && (
                <div className="nav-status-order-detail nav-wizards-container mb-3 mt-3">
                    <nav className="nav nav-wizards-2 mb-3">

                        {orderStatuses && orderStatuses.filter(orderStatus => orderStatus.expected_lifecycle_status
                            && (orderStatus.order_status_code !== 'FACTURA' || values.need_invoice === true)
                        ).map((orderStatus, index) => (

                            <div key={`nav_link__status_${index}`} className="nav-item col" data-tooltip-id={`tooltip_${index}`}>
                                <RouterLink className={`nav-link  ${currentOrderStatus.order_status_code === orderStatus.order_status_code ? 'active' : (values?.orderStatuses && values?.orderStatuses[orderStatus.order_status_code]?.has_passed_status ? 'completed' : 'incompleted')} 
                                    }`}
                                    onClick={() => handleStepClick(index, orderStatus.order_status_code, orderStatus.order_status)}
                                >
                                    <div className="nav-text">{index + 1}. {orderStatus.label}</div>
                                </RouterLink>
                                {currentOrderStatus && currentOrderStatus.order_status_code !== 'CANCELADO' && (

                                    <Tooltip id={`tooltip_${index}`} place="top" style={{ zIndex: 9999 }}>
                                        {orderStatus.order_status_hint}
                                    </Tooltip>
                                )}
                            </div>

                        ))}

                        {currentOrderStatus && (currentOrderStatus.order_status_code === 'CANCELADO' || currentOrderStatus.order_status_code === 'CANCELADO_PAGO_PENDIENTE') && (
                            <div key={`nav_link__status_cancel`} className="nav-item col">
                                <RouterLink className={`nav-link cancelled`}>
                                    <div className="nav-text">{currentOrderStatus.order_status}</div>
                                </RouterLink>

                            </div>
                        )}

                    </nav >
                </div >
            )}


        </>
    );
}

export default OrderStatus;