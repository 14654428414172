import React, { createContext, useContext, useState, useEffect } from "react";

const CommonContext = createContext();

export const CommonProvider = ({ children }) => {
  const [fetchOrder, setFetchOrder] = useState(true);
  const [loading, setLoading] = useState(false);
  const [updateOrder, setUpdateOrder] = useState(false);

  return (
    <CommonContext.Provider
      value={{
        fetchOrder,
        setFetchOrder,
        loading,
        setLoading,
        updateOrder,
        setUpdateOrder,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export const useCommon = () => {
  return useContext(CommonContext);
};
