import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import moment from 'moment';
import FieldsIncidente from './fieldsIncidente.js';
import { useCommon } from '../../../../context/CommonContext.js';
 
function OrderStatusCierre({ fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB, updateOrder }) {
    const { id } = useParams();
    const { Formik } = formik;
    const { fetchOrder, setFetchOrder } = useCommon();

    const fechaHoraContacto = useRef(null);
    const opcionesHaOcurridoIncidente = [
        { label: 'Sí', value: 'SI' },
        { label: 'No', value: 'NO' }
    ]

    return (
        <>
            <Formik
                validationSchema={yup.object().shape({
                    closure_confirmation: yup.boolean().test('is-true', 'Se debe confirmar el cierre de la Orden', value => value === true),
                    incident_ocurred: yup.object().required('Selecciona una opción'),
                    observaciones: yup.string(),

                    incident_datetime: yup.date().when('incident_ocurred', ([incident_ocurred], schema) => {
                        return (incident_ocurred?.value === 'SI' ? schema.required('Selecciona la fecha y hora del incidente') : schema);
                    }),
                    incident_types: yup.array().when('incident_ocurred', ([incident_ocurred], schema) => {
                        return (incident_ocurred?.value === 'SI' ? schema.min(1, "Se debe seleccionar al menos un tipo de incidencia") : schema);
                    }),
                    incident_description: yup.string().when('incident_ocurred', ([incident_ocurred], schema) => {
                        return (incident_ocurred?.value === 'SI' ? schema.required('Escriba la descripción del incidente') : schema);
                    }),
                    resolution_actions: yup.string().when('incident_ocurred', ([incident_ocurred], schema) => {
                        return (incident_ocurred?.value === 'SI' ? schema.required('Escriba la solución del incidente') : schema);
                    }),
                    incident_users: yup.array().when('incident_ocurred', ([incident_ocurred], schema) => {
                        return (incident_ocurred?.value === 'SI' ? schema.min(1, "Se debe seleccionar al menos un usuario implicado en el incidente") : schema);
                    }),


                })}
                initialValues={{
                    closure_confirmation: false,
                    incident_ocurred: '',
                    observaciones: '',

                    incident_datetime: '',
                    incident_types: [],
                    incident_description: '',
                    resolution_actions: '',
                    incident_users: []
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(false);                    
                    await updateOrder();

                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code: 'CIERRE',
                        incident_ocurred: values.incident_ocurred.value==='SI',
                        incident_datetime: moment(values.incident_datetime).format(),
                        incident_types: values.incident_types,
                        incident_description: values.incident_description,
                        resolution_actions: values.resolution_actions,
                        incident_users: values.incident_users,
                        observations: values.observaciones
                    }).then(async response => {
                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);

                        if (modalInstance) {
                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                       // console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    }).finally(()=>setFetchOrder(!fetchOrder))
                    
                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>
                        <div className="modal-header">
                            <h4 className="modal-title">Cierre</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-4">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>{' '}</Form.Label>
                                        <Form.Check
                                            name="closure_confirmation"
                                            type="switch"
                                            label="Confirmo que la orden puede cerrarse"
                                            checked={values.closure_confirmation}
                                            className={errors.closure_confirmation ? 'is-invalid' : ''}
                                            onChange={(e) => {
                                                setFieldValue('closure_confirmation', e.target.checked);
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.closure_confirmation}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                {values.closure_confirmation && (
                                    <>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>¿Ha ocurrido algún Incidente?</Form.Label>
                                                <Select
                                                    name='incident_ocurred'
                                                    options={opcionesHaOcurridoIncidente}
                                                    className={errors.incident_ocurred ? 'is-invalid' : ''}
                                                    placeholder="Selecciona una opción"
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    value={values.incident_ocurred}
                                                    onChange={(e) => {
                                                        setFieldValue("incident_ocurred", e ? e : '');
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.incident_ocurred}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        {values?.incident_ocurred?.value === 'SI' && (
                                            <FieldsIncidente
                                                errors={errors}
                                                values={values}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                            />
                                        )}




                                        <Row className="mb-1">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Observaciones</Form.Label>
                                                <textarea
                                                    name="observaciones"
                                                    className={`form-control ${errors.observaciones ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        setFieldValue('observaciones', e.target.value);
                                                    }}
                                                    value={values.observaciones}
                                                ></textarea>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.observaciones}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                    </>
                                )}


                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" onClick={handleSubmit} disabled={Object.keys(errors).length > 0}>Actualizar estatus</Button>
                        </div>
                    </>
                )}
            </Formik>


        </>
    );
}

export default OrderStatusCierre;