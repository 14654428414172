import React, { useEffect } from 'react';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../context/AuthContext';

function OrderFieldsDatosContacto({ tipoClientes, aseguradoras, paymentTypes, values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors, addNotification }) {

    const { id } = useParams();
    const { isAdmin } = useAuth()

    useEffect(() => {
        if (values?.client_type_id?.client_type_code === 'PARTICULAR' || values?.tipo_pago_id?.code !== 'CONTADO/CREDITO') {
            //quitar comentario setFieldValue("excedente_asegurado", '');
            //quitar comentario setFieldValue("monto_aseguradora_paga", '');
            
        }

        if (!id && values?.client_type_id?.client_type_code === 'ASEGURADORA' && values?.tipo_pago_id?.code === 'CONTADO/CREDITO') {
            addNotification('warning', 'Excedente asegurado', `El campo Excedente asegurado aparecerá al final de la tabla de costos.`, 'top-right');
        }
    }, [
        values.tipo_pago_id,
        values.client_type_id
    ]);



    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Tipo de Cliente</Form.Label>
                    <Select
                        name='client_type_id'
                        options={tipoClientes}
                        className={errors.client_type_id ? 'is-invalid' : ''}
                        placeholder="Selecciona el tipo de cliente"
                        isClearable={true}
                        isSearchable={true}
                        value={values.client_type_id}
                        onChange={(e) => {
                            setFieldValue("insurance_id", '');
                            setFieldValue("client_type_id", e ? e : '');
                        }}
                        isDisabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.client_type_id}
                    </Form.Control.Feedback>


                </Form.Group>
            </Row>

            {values.client_type_id && values.client_type_id.client_type_code === 'ASEGURADORA' && (
                <Row className="mb-3">

                    <Form.Group as={Col} md="12" >
                        <Form.Label>Aseguradora</Form.Label>
                        <Select
                            name="insurance_id"
                            options={aseguradoras}
                            className={errors.insurance_id ? 'is-invalid' : ''}
                            placeholder="Selecciona una aseguradora"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.insurance_id}
                            onChange={(e) => {
                                setFieldValue("insurance_id", e);
                            }}
                            isDisabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.insurance_id}
                        </Form.Control.Feedback>


                    </Form.Group>
                </Row>
            )}


            <Row className="mb-3">
                <Form.Group as={Col} md="2" >
                    <Form.Label>{' '}</Form.Label>
                    <Form.Check
                        name="cliente_vip"
                        type="switch"
                        label="Cliente VIP"
                        checked={values.cliente_vip}
                        onChange={(e) => {
                            setFieldValue('cliente_vip', e.target.checked);
                        }}
                        disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                    />
                </Form.Group>
                <Form.Group as={Col} md="7" >
                    <Form.Label>Beneficiario del servicio</Form.Label>
                    <Form.Control
                        type="text"
                        name="beneficiario_servicio"
                        placeholder="Beneficiario del servicio"
                        value={values.beneficiario_servicio}
                        onChange={handleChange}
                        isInvalid={!!errors.beneficiario_servicio}
                        autoComplete="off"
                        disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.beneficiario_servicio}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" >
                    <Form.Label>Teléfono</Form.Label>
                    <InputMask
                        name='telefono_principal'
                        mask="(999) 999-9999"
                        className="form-control"
                        placeholder="(999) 999-9999"
                        value={values.telefono_principal}
                        onChange={handleChange}
                        disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.telefono_principal}
                    </Form.Control.Feedback>
                </Form.Group>
                

            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="5" >
                    <Form.Label>Empresa</Form.Label>
                    <Form.Control
                        type="text"
                        name="empresa"
                        placeholder="Empresa"
                        value={values.empresa}
                        onChange={handleChange}
                        isInvalid={!!errors.empresa}
                        autoComplete="off"
                        disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.empresa}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="7" >
                    <Form.Label>Autorización de cabina</Form.Label>
                    <Form.Control
                        type="text"
                        name="autorizacion_cabina"
                        placeholder="Autorización de cabina"
                        value={values.autorizacion_cabina}
                        onChange={handleChange}
                        isInvalid={!!errors.autorizacion_cabina}
                        autoComplete="off"
                        disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.autorizacion_cabina}
                    </Form.Control.Feedback>
                </Form.Group>

            </Row>
            {values.montacarga !== true && (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" >
                        <Form.Label>Número de póliza</Form.Label>
                        <Form.Control
                            type="text"
                            name="numero_poliza"
                            placeholder="Número de póliza"
                            value={values.numero_poliza}
                            onChange={handleChange}
                            isInvalid={!!errors.numero_poliza}
                            autoComplete="off"
                            disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.numero_poliza}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" >
                        <Form.Label>Número de siniestro</Form.Label>
                        <Form.Control
                            type="text"
                            name="numero_siniestro"
                            placeholder="Número de siniestro"
                            value={values.numero_siniestro}
                            onChange={handleChange}
                            isInvalid={!!errors.numero_siniestro}
                            autoComplete="off"
                            disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.numero_siniestro}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" >
                        <Form.Label>Número de folio</Form.Label>
                        <Form.Control
                            type="text"
                            name="numero_folio"
                            placeholder="Número de folio"
                            value={values.numero_folio}
                            onChange={handleChange}
                            isInvalid={!!errors.numero_folio}
                            autoComplete="off"
                            disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.numero_folio}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Row>
            )}

            <Row className="mb-3">

                <Form.Group as={Col} md={`${values?.client_type_id?.client_type_code !== 'PARTICULAR' && values?.tipo_pago_id?.code === 'CONTADO/CREDITO' ? '12' : '12'}`} >
                    <Form.Label>Tipo de Pago</Form.Label>
                    <Select
                        name='tipo_pago_id'
                        options={paymentTypes}
                        className={errors.tipo_pago_id ? 'is-invalid' : ''}
                        placeholder="Selecciona el tipo de pago"
                        isClearable={true}
                        isSearchable={true}
                        value={values.tipo_pago_id}
                        onChange={(e) => {
                            setFieldValue("tipo_pago_id", e ? e : '');
                        }}
                        isDisabled={values.orderStatuses?.PAGADO_CONTADO?.has_passed_status || values.orderStatuses?.ENTREGA?.has_passed_status}

                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.tipo_pago_id}
                    </Form.Control.Feedback>

                </Form.Group>
                {0 === 1 && values?.client_type_id?.client_type_code !== 'PARTICULAR' && values?.tipo_pago_id?.code === 'CONTADO/CREDITO' && (
                    <Form.Group as={Col} md='6' >
                        <Form.Label>Excedente asegurado (neto)</Form.Label>


                        <InputGroup
                            className={`mb-3 ${errors.excedente_asegurado ? 'is-invalid' : ''}`}
                        >
                            <InputGroup.Text >$</InputGroup.Text>
                            <CurrencyInput
                                className={`form-control ${errors.excedente_asegurado ? 'is-invalid' : ''}`}
                                prefix=""
                                allowNegativeValue={false}
                                decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                decimalScale={2}
                                decimalSeparator="."
                                groupSeparator=","
                                name="excedente_asegurado"
                                value={values.excedente_asegurado}
                                onValueChange={(value) => setFieldValue("excedente_asegurado", value)}
                                autoComplete='off'
                                disabled={values.orderStatuses?.PAGADO_CONTADO?.has_passed_status}
                            />
                        </InputGroup>
                        {errors.excedente_asegurado ? (
                            <div className="invalid-feedback">{errors.excedente_asegurado}</div>
                        ) : null}

                    </Form.Group>

                )}



            </Row>
            <Row className="mb-1">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Observaciones sobre Datos de Contacto y Seguimiento</Form.Label>

                    <textarea
                        name="datos_contacto_seguimiento_observaciones"
                        className={`form-control ${errors.datos_contacto_seguimiento_observaciones ? 'is-invalid' : ''}`}
                        onChange={(e) => {
                            setFieldValue('datos_contacto_seguimiento_observaciones', e.target.value);
                        }}
                        value={values.datos_contacto_seguimiento_observaciones}
                        disabled={Boolean(values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status) && !isAdmin()}

                    ></textarea>

                    <Form.Control.Feedback type="invalid">
                        {errors.datos_contacto_seguimiento_observaciones}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>
        </>
    );
}

export default OrderFieldsDatosContacto;